import React, { useState, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

import BookmarkList from '../common/BookmarkList';
import SearchList from '../common/SearchList';
import { FloatContainer } from '../../styled/SummonerStyle';

const Float = () => {
  const tabSelectorRef = useRef(null);
  const [isFloatOn, setIsFloatOn] = useState(0);
  const [isFixed, setIsFixed] = useState(false);
  const [changeBookmark, setChangeBookmark] = useState([]);

  // 북마크 변경 이벤트
  const handleChangeBookmark = () => {
    setChangeBookmark(!changeBookmark);
  };

  // 스크롤 이벤트
  const throttledScroll = useMemo(
    () =>
      _.throttle(() => {
        window.scrollY >= 168 ? setIsFixed(true) : setIsFixed(false);
        if (!tabSelectorRef.current) return;
        const nextTabnavOn = window.scrollY > tabSelectorRef.current.offsetTop + 100;
        if (nextTabnavOn !== isFloatOn) setIsFloatOn(nextTabnavOn);
      }, 200),
    [isFloatOn]
  );

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll);
    return () => {
      window.removeEventListener('scroll', throttledScroll);
    };
  }, [throttledScroll]);

  return (
    <FloatContainer className={`${isFixed ? 'fixed' : 'not_fixed'}`}>
      <div className="float left">
        <div className="advertising">광고</div>
      </div>
      <div className="float right">
        <div className="history">
          <div className="box">
            <h4>즐겨찾기</h4>
            <BookmarkList changeBookmark={changeBookmark} />
          </div>
          <div className="box">
            <h4>최근 검색</h4>
            <SearchList handleChangeBookmark={handleChangeBookmark} />
          </div>
        </div>
        <div className="ai_score">
          <h3>AI-Score란?</h3>
          <div className="box">
            <p>DeepLoL이 개발한 인공지능 모델을 통해 게임에서 발생하는 다양한 데이터를 각 시간대별로 평가하여 0~100 사이의 AI-Score를 계산합니다.</p>
            <img src="/images/img_info_aiscore_kr.png" alt="AI Score 소개" />
          </div>
        </div>
        <div className="advertising">광고</div>
      </div>
    </FloatContainer>
  );
};

export default Float;
