import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { Home, Summoner, Multi } from './components';
import NotFound from './components/NotFound';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/summoner/:userName">
          <Summoner />
        </Route>
        <Route exact path="/summoner/">
          <Redirect to="/" />
        </Route>
        <Route path="/multi">
          <Multi />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
