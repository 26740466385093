import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import Overview from './Overview';
import MatchItem from './MatchItem';
import { MatchListContainer } from '../../styled/SummonerStyle';

const MatchList = ({ isUpdated, updatePer, handleMoreGame, moreDisabled, gameLength }) => {
  const summoner = useSelector((state) => state.summoner.info);
  const [matchInfo, setMatchInfo] = useState([]);
  const [openId, setOpenId] = useState(0);
  const [queueNum, setQueueNum] = useState(0);
  const [champId, setChampId] = useState(0);

  // 챔피언 선택
  const handleChampFilter = (champId) => {
    const result = Number(champId);
    setChampId(result);
  };

  // 큐 선택
  const handleQueueFilter = (queueNum) => {
    setQueueNum(queueNum);
  };

  // 정보 더 보기
  const handleMoreInfo = (gameId) => {
    const currentId = openId;
    if (currentId === gameId) {
      setOpenId(0);
    } else {
      setOpenId(gameId);
    }
  };

  // 소환사 match item 정보 콜백
  const matchCallback = (params) => {
    let sortArr = [];
    sortArr = params.sort((a, b) => {
      if (a.date_timestamp < b.date_timestamp) return 1;
      if (a.date_timestamp > b.date_timestamp) return -1;
      return 0;
    });
    setMatchInfo(sortArr);
  };

  // 소환사 match item 정보
  const getMatch = useCallback((matchArr) => {
    let arr = [];
    matchArr.map(async (matchId) => {
      return await axios
        .get(
          `${process.env.REACT_APP_API}matchjson?gameId=${matchId}&season=11&region=KR&saveNew=0`
        )
        .then((res) => {
          const data = res.data;
          arr = [...arr, data];

          // 콜백처럼 사용
          if (matchArr.length === arr.length) {
            matchCallback(arr);
          }
        });
    });
  }, []);

  // 소환사 match list 정보
  const getMatchs = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}match?summonerId=${summoner.summoner_id}&season=11&region=KR&offset=0&gameNum=${gameLength}&queue=${queueNum}&championId=${champId}`
      )
      .then((res) => {
        const data = res.data;
        data.sort(function (a, b) {
          return b - a;
        });
        if (data.length !== 0) getMatch(data);
        else setMatchInfo([]);
      });
  }, [summoner.summoner_id, gameLength, queueNum, champId, getMatch]);

  useEffect(() => {
    if (isUpdated) {
      getMatchs();
    }
  }, [getMatchs, isUpdated]);

  return (
    <>
      <Overview
        matchInfo={matchInfo}
        handleQueueFilter={handleQueueFilter}
        handleChampFilter={handleChampFilter}
        queueNum={queueNum}
      />
      {matchInfo.length !== 0 ? (
        <>
          <MatchListContainer>
            {matchInfo.map((match, index) => {
              return (
                <MatchItem
                  key={index}
                  match={match}
                  openId={openId}
                  handleMoreInfo={handleMoreInfo}
                />
              );
            })}
            <button
              className="more_game"
              disabled={moreDisabled}
              onClick={() => handleMoreGame(summoner, 'btnClick')}
            >
              <i className="fill" style={{ width: updatePer + '%' }}></i>
              <span>{`${updatePer !== 0 ? updatePer + '%' : '더보기'}`}</span>
            </button>
          </MatchListContainer>
        </>
      ) : (
        <div className="no_game">
          <p>최근 게임 정보가 없습니다.</p>
        </div>
      )}
    </>
  );
};

export default React.memo(MatchList);
