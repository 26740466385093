// 게임시간 가져오기
export const getGameTime = (unixTime) => {
  const gameDate = new Date(unixTime * 1000);
  const nowDate = new Date();
  const diffDate = new Date(nowDate - gameDate);
  const minutes = Math.floor(diffDate / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  let result = 0;
  if (minutes > 60) {
    if (hours > 24) {
      if (days > 365) {
        result = '오래 전';
      } else {
        result = Math.abs(days) + ' 일 전';
      }
    } else {
      result = Math.abs(hours) + ' 시간 전';
    }
  } else {
    result = Math.abs(minutes) + ' 분 전';
  }

  return result;
};

// 게임시간 시/분/초 구하기
export const getTimeHMS = (time, num) => {
  let tmp = time.split(':');
  let hour = parseInt(tmp[0]);
  let minute = parseInt(tmp[1]);
  let second = parseInt(tmp[2]);

  if (num === 'num') {
    return hour !== 0
      ? `${hour}:${minute}:${second}`
      : second < 10
      ? `${minute}:0${second}`
      : `${minute}:${second}`;
  } else {
    return hour !== 0 ? `${hour}시간 ${minute}분 ${second}초` : `${minute}분 ${second}초`;
  }
};

// 시간 포멧
export const getFormatTime = (time) => {
  // time은 xx.xx 형식
  let returnVal = '';
  let min = Math.floor(time);
  let sec = time - Math.floor(time);
  if (sec === 0) {
    sec = '00';
  } else {
    if (Math.round(sec * 60) === 60) {
      min += 1;
      sec = '00';
    } else {
      sec = (Math.round(sec * 60) < 10 ? '0' : '') + Math.round(sec * 60);
    }
  }
  returnVal = min + ':' + sec;
  return returnVal;
};

// 팀별 우세도 계산
export const getWinrateCalc = (winrate, team) => {
  let result = 0;
  if (winrate > 0) {
    // 블루가 우세
    team === 'blue' ? (result = winrate + 50) : (result = winrate - 50);
  } else {
    // 레드가 우세
    team === 'blue' ? (result = 100 + (winrate - 50)) : (result = winrate - 50);
  }
  result = Math.abs(result);
  return result;
};

// 음수 양수 판단
export const getNeGa = (arg) => {
  let result = '';
  if (arg === 0) {
    result = '';
  } else if (arg > 0) {
    result = 'positive';
  } else {
    result = 'negative';
  }
  return result;
};

// LP Score 계산
export const getLPScore = (tier, division, lp) => {
  let returnValue = lp;
  let divisionCount = 4;

  if (tier === 'IRON') {
    divisionCount *= 1;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'BRONZE') {
    divisionCount *= 2;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'SILVER') {
    divisionCount *= 3;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'GOLD') {
    divisionCount *= 4;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'PLATINUM') {
    divisionCount *= 5;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'DIAMOND') {
    divisionCount *= 6;
    returnValue += 100 * (divisionCount - division);
  } else if (tier === 'MASTER') {
    returnValue += 2400;
  } else if (tier === 'GRANDMASTER') {
    returnValue += 3400;
  } else if (tier === 'CHALLENGER') {
    returnValue += 4400;
  }

  return returnValue;
};

// 승률 구하기
export const getWinrate = (win, lose) => {
  let result = Math.round((win / (win + lose)) * 100);
  return result;
};

// KDA 구하기
export const getKda = (k, d, a) => {
  let result = 0;
  if (d === 0) {
    result = Math.round((k + a) * 1.2 * 100) / 100;
  } else {
    result = Math.round(((k + a) / d) * 100) / 100;
  }
  return result;
};

// AI Score 구하기
export const getAIScore = (minmax_z) => {
  let scoreResult = 0;
  scoreResult = (minmax_z * 100).toFixed(0);

  return scoreResult;
};

// 배열의 중복데이터 중 최다 value와 중복 횟수 구하기 (내림차순으로 return)
export const getSortedArr = (array) => {
  // 1. 출연 빈도 구하기
  let counts = array.reduce((pv, cv) => {
    pv[cv] = (pv[cv] || 0) + 1;
    return pv;
  }, {});

  // 2. 요소와 개수를 표현하는 배열 생성 => [ [요소: 개수], [요소: 개수], ...]
  let result = [];
  let realResult = [];
  for (let key in counts) {
    result.push([key, counts[key]]);
  }

  // 3. 출현 빈도별 정리하기
  result.sort((first, second) => {
    // 정렬 순서 바꾸려면 return first[1] - second[1];
    return second[1] - first[1];
  });

  // 4. 정렬된 2차원 배열을 1차원으로 변경
  result.forEach((result, idx) => {
    result.pop();
    realResult.push(result[0].toString());
  });

  // 배열이 리턴
  return realResult;
};

// 티어 구하기, 티어 예측 구하기
export const getTierScore = (tier, aiscore) => {
  let resultNum = 0;
  let resultTxt = '';
  let predictNum = 0;

  if (48 > aiscore) {
    predictNum += Math.floor((aiscore - 48) / 5) * 100;
  } else if (52 < aiscore) {
    predictNum += Math.ceil((aiscore - 52) / 5) * 100;
  } else {
    predictNum = 0;
  }

  if (typeof tier === 'string') {
    let str = tier.split(' ');
    switch (str[0]) {
      case 'CHALLENGER':
        resultNum = 2600;
        resultTxt = 'C';
        break;
      case 'GRANDMASTER':
        resultNum = 2500;
        resultTxt = 'GM';
        break;
      case 'MASTER':
        resultNum = 2400;
        resultTxt = 'M';
        break;
      case 'DIAMOND':
        resultNum = 2000;
        resultTxt = 'D';
        break;
      case 'PLATINUM':
        resultNum = 1600;
        resultTxt = 'P';
        break;
      case 'GOLD':
        resultNum = 1200;
        resultTxt = 'G';
        break;
      case 'SILVER':
        resultNum = 800;
        resultTxt = 'S';
        break;
      case 'BRONZE':
        resultNum = 400;
        resultTxt = 'B';
        break;
      case 'IRON':
        resultNum = 0;
        resultTxt = 'I';
        break;
      default:
        break;
    }

    if (str[1] !== undefined) {
      switch (str[1]) {
        case 'IV':
          resultNum += 0;
          resultTxt += '4';
          break;
        case 'III':
          resultNum += 100;
          resultTxt += '3';
          break;
        case 'II':
          resultNum += 200;
          resultTxt += '2';
          break;
        case 'I':
          resultNum += 300;
          resultTxt += '1';
          break;
        default:
          break;
      }
    }
  } else {
    tier += predictNum;
    if (0 > tier) {
      resultTxt = 'I4';
    } else if (0 <= tier && tier < 100) {
      resultTxt = 'I4';
    } else if (100 <= tier && tier < 200) {
      resultTxt = 'I3';
    } else if (200 <= tier && tier < 300) {
      resultTxt = 'I2';
    } else if (300 <= tier && tier < 400) {
      resultTxt = 'I1';
    } else if (400 <= tier && tier < 500) {
      resultTxt = 'B4';
    } else if (500 <= tier && tier < 600) {
      resultTxt = 'B3';
    } else if (600 <= tier && tier < 700) {
      resultTxt = 'B2';
    } else if (700 <= tier && tier < 800) {
      resultTxt = 'B1';
    } else if (800 <= tier && tier < 900) {
      resultTxt = 'S4';
    } else if (900 <= tier && tier < 1000) {
      resultTxt = 'S3';
    } else if (1000 <= tier && tier < 1100) {
      resultTxt = 'S2';
    } else if (1100 <= tier && tier < 1200) {
      resultTxt = 'S1';
    } else if (1200 <= tier && tier < 1300) {
      resultTxt = 'G4';
    } else if (1300 <= tier && tier < 1400) {
      resultTxt = 'G3';
    } else if (1400 <= tier && tier < 1500) {
      resultTxt = 'G2';
    } else if (1500 <= tier && tier < 1600) {
      resultTxt = 'G1';
    } else if (1600 <= tier && tier < 1700) {
      resultTxt = 'P4';
    } else if (1700 <= tier && tier < 1800) {
      resultTxt = 'P3';
    } else if (1800 <= tier && tier < 1900) {
      resultTxt = 'P2';
    } else if (1900 <= tier && tier < 2000) {
      resultTxt = 'P1';
    } else if (2000 <= tier && tier < 2100) {
      resultTxt = 'D4';
    } else if (2100 <= tier && tier < 2200) {
      resultTxt = 'D3';
    } else if (2200 <= tier && tier < 2300) {
      resultTxt = 'D2';
    } else if (2300 <= tier && tier < 2400) {
      resultTxt = 'D1';
    } else if (2400 <= tier && tier < 2500) {
      resultTxt = 'M';
    } else if (2500 <= tier && tier < 2600) {
      resultTxt = 'GM';
    } else {
      resultTxt = 'C';
    }
  }

  return { num: resultNum, txt: resultTxt };
};

// 객체 안의 객체 정렬
export const getSortedObject = (data, attr, flag) => {
  let arr = [];
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      let obj = {};
      obj[prop] = data[prop];
      obj.tempSortName = data[prop][attr];
      arr.push(obj);
    }
  }

  if (flag === 'ascending') {
    arr.sort(function (a, b) {
      let at = a.tempSortName,
        bt = b.tempSortName;
      return at > bt ? 1 : at < bt ? -1 : 0;
    });
  } else {
    arr.sort(function (a, b) {
      let at = a.tempSortName,
        bt = b.tempSortName;
      return at < bt ? 1 : at > bt ? -1 : 0;
    });
  }

  let result = [];
  for (let i = 0, l = arr.length; i < l; i++) {
    let obj = arr[i];
    delete obj.tempSortName;
    let id = 0;
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        id = prop;
      }
    }
    let item = obj[id];
    item.cid = Number(id);
    result.push(item);
  }

  // 배열이 리턴
  return result;
};

// 배열 객체를 정렬
export const getSortedArray = (array) => {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    for (const key in array[i]) {
      result.push(array[i][key]);
    }
  }

  return result;
};

// 데미지를 퍼센트로 바꾸기
export const getDamagePercent = (arg, array, txt) => {
  let maxDamage = getSortedObject(getSortedArray(array), txt);
  let result = ((arg / maxDamage[0][txt]) * 100).toFixed(1);

  return result;
};

// 챔피언 중에 스킬 마스터 렙이 다른 챔피언이 있어서 스킬 마스터 count를 구해야함!
export const getMaxCount = (type, championId) => {
  /***
		* championId
		- 77 : 우디르 (6 6 6 6)
		- 126 : 제이스 (6 6 6 1)
		- 350 : 유미 (6 5 5 3)
		- 523 : 아펠리오스 (6 6 6 3)
	***/
  let count = 5;
  if (type === 'Q') {
    if (
      championId === '77' ||
      championId === '126' ||
      championId === '350' ||
      championId === '523'
    ) {
      count = 6;
    }
  } else if (type === 'W') {
    if (championId === '77' || championId === '126' || championId === '523') {
      count = 6;
    }
  } else if (type === 'E') {
    if (championId === '77' || championId === '126' || championId === '523') {
      count = 6;
    }
  }

  return count;
};

// 점수 색상 바꾸기 (4 color)
export const scoreColor = (num = '-') => {
  if (num >= 75) {
    return <span className="score_color verygood">{num}</span>;
  } else if (num >= 50) {
    return <span className="score_color good">{num}</span>;
  } else if (num >= 25) {
    return <span className="score_color bad">{num}</span>;
  } else {
    return <span className="score_color verybad">{num}</span>;
  }
};

// kda 색상 바꾸기 (3 color)
export const kdaColor = (num) => {
  if (num >= 5) {
    return <span className="kda_color high">{num}</span>;
  } else if (num >= 3) {
    return <span className="kda_color middle">{num}</span>;
  } else {
    return <span className="kda_color low">{num}</span>;
  }
};

// 티어 뱃지 변경
export const tierBadge = (tier) => {
  if (tier === 'C') {
    return <span className="tier_badge challenger">{tier}</span>;
  } else if (tier === 'GM') {
    return <span className="tier_badge grandmaster">{tier}</span>;
  } else if (tier === 'M') {
    return <span className="tier_badge master">{tier}</span>;
  } else {
    let result = tier.split('');
    switch (result[0]) {
      case 'D':
        return <span className="tier_badge diamond">{tier}</span>;
      case 'P':
        return <span className="tier_badge platinum">{tier}</span>;
      case 'G':
        return <span className="tier_badge gold">{tier}</span>;
      case 'S':
        return <span className="tier_badge silver">{tier}</span>;
      case 'B':
        return <span className="tier_badge bronze">{tier}</span>;
      case 'I':
        return <span className="tier_badge iron">{tier}</span>;
      default:
        return tier;
    }
  }
};

// 티어 텍스트 변경
export const tierText = (tier, division) => {
  switch (tier) {
    case 'CHALLENGER':
      return <span className="tier_color challenger">Challenger</span>;
    case 'GRANDMASTER':
      return <span className="tier_color grandmaster">Grandmaster</span>;
    case 'MASTER':
      return <span className="tier_color master">Master</span>;
    case 'DIAMOND':
      return <span className="tier_color diamond">Diamond {`${division}`}</span>;
    case 'PLATINUM':
      return <span className="tier_color platinum">Platinum {`${division}`}</span>;
    case 'GOLD':
      return <span className="tier_color gold">Gold {`${division}`}</span>;
    case 'SILVER':
      return <span className="tier_color silver">Silver {`${division}`}</span>;
    case 'BRONZE':
      return <span className="tier_color bronze">Bronze {`${division}`}</span>;
    case 'IRON':
      return <span className="tier_color iron">Iron {`${division}`}</span>;
    default:
      return <span className="tier_color unrank">Unrank</span>;
  }
};

// 평균 티어 텍스트 변경2
export const tierAvgText = (tier) => {
  let tmp = tier.split(' ');
  return (
    <>
      <span className="tier_avg t">{tmp[0]}</span>
      <span className="tier_avg d">{tmp[1] ?? ''}</span>
    </>
  );
};

// 분당 cs구하기
export const csPerMinute = (cs, time) => {
  let tmp = time.split(':');
  let minute = parseInt(tmp[1]);

  return (cs / minute).toFixed(1);
};

// 라인이름 줄이기
export const cutLaneName = (lane) => {
  let result = '';
  switch (lane) {
    case 'Top':
      result = 'Top';
      break;
    case 'Middle':
      result = 'Mid';
      break;
    case 'Jungle':
      result = 'Jug';
      break;
    case 'Bot':
      result = 'Bot';
      break;
    case 'Supporter':
      result = 'Sup';
      break;
    default:
      result = 0;
      break;
  }
  return result;
};
