import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { changeVersion } from '../../store/modules/gameVersion';
import { imageStore } from '../../store/modules/imageStore';
import { Header } from '../../components';
import MultiSearch from './MultiSearch';
import MultiList from './MultiList';
import { MultiContainer } from '../../styled/MultiStyle';

const Multi = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isSearched, setIsSearched] = useState(false);
  const [multiList, setMultiList] = useState([]);
  const [inpText, setInpText] = useState('');
  const [copy, setCopy] = useState({
    copyUrl: window.location.href,
    copied: false,
  });
  const [avg, setAvg] = useState(0);

  // ai score 평균
  const getAvg = (num) => {
    setAvg(num);
  };

  // 공유하기
  const handleCopy = () => {
    setCopy({ copied: true });
    closeCopied();
  };

  // 공유하기 툴팁
  const closeCopied = () => {
    setTimeout(() => {
      setCopy({ copied: false });
    }, 2000);
  };

  // 소환사 하나씩 데이터 받아오기
  const getSummonerData = async (summonerName) => {
    let name = summonerName;
    // 두글자면 띄어쓰기 삽입
    if (summonerName.length === 2) {
      const result = summonerName.split('').join('%20');
      name = result;
    }
    // 공백제거
    if (summonerName.indexOf(' ') !== -1) {
      const result = summonerName.replace(' ', '%20');
      name = result;
    }

    await axios
      .get(
        `${process.env.REACT_APP_API}get_multisearch?summonerName=${encodeURI(
          name
        )}&season=11&region=KR`
      )
      .then((res) => {
        const result = res.data.result.a;

        if (result === undefined) return;
        setMultiList((multiList) => [...multiList, result]);
        setIsSearched(true);
      })
      .catch((err) => {
        console.log(err);
        setIsSearched(false);
      });
  };

  // 소환사명 받아서 하나씩 배열로 변경
  const getSummonersName = (text) => {
    let arr_str = text.replace(/님이|로비에|참가하셨습니다.|(,\s)|,|\n|\r/gi, '/');
    arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
    arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
    arr_str = arr_str.replace(/\/$/gi, '');
    arr_str = arr_str.split('/');

    // 중복, 빈값 제거
    const set = new Set(arr_str);
    const uniqueArr = [...set];
    const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

    setInpText(modyArr);
    setMultiList([]);

    history.push({
      pathname: `/multi/${modyArr}`,
      state: { name: modyArr },
    });
  };

  // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
  const getImageInfo = useCallback(
    async (version) => {
      let _thisGameVersionArr = version.split('.');
      let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

      const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
      const resRunes = await axios.get(
        `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resSpells = await axios.get(
        `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resItems = await axios.get(
        `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=ko_KR`
      );
      dispatch(
        imageStore(
          resChampions.data.champions,
          resRunes.data,
          resSpells.data.data,
          resItems.data.data
        )
      );
    },
    [dispatch]
  );

  // 게임 버전 스토어 등록
  const getGameVersion = useCallback(async () => {
    const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
    dispatch(changeVersion(res.data[0]));

    // 이미지 정보 함수호출
    getImageInfo(res.data[0]);
  }, [dispatch, getImageInfo]);

  // getSummonerData 순서대로 호출
  const orderCall = useCallback(async (name) => {
    for (const item of name) {
      await getSummonerData(item);
    }
  }, []);

  useEffect(() => {
    // 게임버전 가져오기
    getGameVersion();

    setIsSearched(false);
    setMultiList([]);
    setAvg(0);

    let text = decodeURI(location.pathname).slice(7);
    let name = text.split(',');

    orderCall(name);

    setInpText(text);
    setCopy({ copyUrl: window.location.href });
  }, [getGameVersion, orderCall, location]);

  return (
    <>
      <Header />
      <MultiContainer>
        <div className="multi_top">
          <div className="center">
            <MultiSearch getSummonersName={getSummonersName} inpText={inpText} />
            <div className="info">
              <div className="box">
                <h4>팀평균 AI-Score</h4>
                <div className="bg">
                  <p>{isNaN(avg) ? '0' : avg}</p>
                </div>
              </div>
            </div>
            <div className="buttons">
              <button className="ingame" onClick={() => alert('준비중입니다. ')}>
                인게임
              </button>
              <CopyToClipboard onCopy={handleCopy} text={copy.copyUrl}>
                <button className="share">공유</button>
              </CopyToClipboard>
              {copy.copied && (
                <span className="share_tooltip">클립보드에 링크가 복사되었습니다.</span>
              )}
            </div>
          </div>
        </div>
        <div className="multi_bottom">
          <div className="center">
            {!isSearched ? (
              <div className="how_to_use">
                <h3>멀티 서치 사용 방법</h3>
                <div className="box">
                  <p>
                    채팅창의 내용을 <span>복사+붙여넣기</span> 하여 게임에 참여중인 모든 소환사의
                    정보를 요약하여 볼 수 있습니다
                  </p>
                  <div className="imgs">
                    <img src="/images/img_picks.png" alt="픽창" className="picks" />
                    <img src="/images/icon_s_expand_arrow.svg" alt="화살표" />
                    <img src="/images/img_browser.png" alt="브라우저" className="browser" />
                  </div>
                </div>
              </div>
            ) : (
              <MultiList multiList={multiList} getAvg={getAvg} />
            )}
          </div>
        </div>
      </MultiContainer>
    </>
  );
};

export default Multi;
