// 액션 생성
const SUMMONER = 'summoner/SUMMONER';

// 액션 함수 내보내기
export const changeSummoner = (info) => ({ type: SUMMONER, info });

// 초기 상태 값
const initialState = {
  info: {},
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUMMONER:
      return {
        info: action.info,
      };
    default:
      return state;
  }
};
export default reducer;
