import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImageRune, ImageItem, ImageSkill } from '../../components';
import { getMaxCount } from '../../function';
import { openModal } from '../../store/modules/modal';
import { ModalContainer } from '../../styled/CommonStyle';

const Modal = ({ modalData, open }) => {
  const runeArr = useSelector((state) => state.imageStore.runes);
  const dispatch = useDispatch();
  const [minute, setMinute] = useState([]);
  const [skills, setSkills] = useState({});
  const [master, setMaster] = useState([]);
  const [runes, setRunes] = useState({});

  const initialFunc = useCallback(() => {
    /*** ITEM ***/
    let minArr = [];
    modalData.item_seq.forEach((item) => {
      minArr.push(item.min);
    });
    setMinute([...new Set(minArr)]); // min(분) 배열 구해서 중복 제거

    /*** SKILL ***/
    let skillQ = [];
    let skillW = [];
    let skillE = [];
    let skillR = [];
    let skillPlus = [];
    let qCount = 0;
    let wCount = 0;
    let eCount = 0;
    let rCount = 0;
    let qAllCount = getMaxCount('Q', modalData.championId);
    let wAllCount = getMaxCount('W', modalData.championId);
    let eAllCount = getMaxCount('E', modalData.championId);
    let rAllCount = getMaxCount('R', modalData.championId);
    let successArr = [];

    modalData.skill_seq.forEach((item, index) => {
      let level = index + 1;
      if (item.skillId === 1) {
        skillQ.push(level);
        skillW.push(0);
        skillE.push(0);
        skillR.push(0);
        skillPlus.push(0);

        qCount++;
        if (qCount === qAllCount) {
          successArr.push({
            skill: 'Q',
            icon: 1,
          });
        }
      } else if (item.skillId === 2) {
        skillQ.push(0);
        skillW.push(level);
        skillE.push(0);
        skillR.push(0);
        skillPlus.push(0);
        wCount++;
        if (wCount === wAllCount) {
          successArr.push({
            skill: 'W',
            icon: 2,
          });
        }
      } else if (item.skillId === 3) {
        skillQ.push(0);
        skillW.push(0);
        skillE.push(level);
        skillR.push(0);
        skillPlus.push(0);
        eCount++;
        if (eCount === eAllCount) {
          successArr.push({
            skill: 'E',
            icon: 3,
          });
        }
      } else if (item.skillId === 4) {
        skillQ.push(0);
        skillW.push(0);
        skillE.push(0);
        skillR.push(level);
        skillPlus.push(0);
        rCount++;
        if (rCount === rAllCount) {
          successArr.push({
            skill: 'R',
            icon: 4,
          });
        }
      } else {
        skillQ.push(0);
        skillW.push(0);
        skillE.push(0);
        skillR.push(0);
        skillPlus.push(level);
      }
    });
    setSkills({ skillQ, skillW, skillE, skillR, skillPlus });

    let countArr = [
      {
        skill: 'Q',
        count: qCount,
        icon: 1,
      },
      {
        skill: 'W',
        count: wCount,
        icon: 2,
      },
      {
        skill: 'E',
        count: eCount,
        icon: 3,
      },
    ];
    countArr.sort(function (a, b) {
      return b.count - a.count;
    });

    if (successArr.length === 0) {
      // 모든 스킬 마스터 X
      setMaster(countArr);
    } else if (successArr.length === 3) {
      // 모든 스킬 마스터 O
      setMaster(successArr);
    } else {
      // 한개 or 두개 스킬 마스터 O
      let successSkill = '';
      let skillArr = [];
      successArr.forEach((item) => {
        successSkill += item.skill;
      });
      countArr.forEach((item) => {
        if (!successSkill.includes(item.skill)) {
          skillArr.push(item);
        }
      });
      let newArr = successArr.concat(skillArr);
      setMaster(newArr);
    }

    /*** RUNE ***/
    let perkPrimary = {};
    let perkSub = {};
    runeArr.forEach((item) => {
      if (item.id === modalData.rune_detail.perkPrimaryStyle) {
        perkPrimary = item;
      } else if (item.id === modalData.rune_detail.perkSubStyle) {
        perkSub = item;
      }
    });
    setRunes({ perkPrimary, perkSub });
  }, [modalData, runeArr]);

  useEffect(() => {
    initialFunc();
  }, [initialFunc]);

  return (
    <ModalContainer className={open ? 'openModal' : 'closeModal'}>
      <div className="dimm" onClick={() => dispatch(openModal(false, {}))}></div>

      <div className="modal">
        <h4>아이템 빌드</h4>
        <div className="build items">
          {minute.map((m, index) => {
            return (
              <div className="items_per_min" key={index}>
                <div className="items">
                  {modalData.item_seq.map(
                    (item, index) =>
                      m === item.min && (
                        <span key={index} className={`${item.type}`}>
                          <ImageItem itemId={item.itemId} />
                        </span>
                      )
                  )}
                </div>
                <span className="times">{m}분</span>
              </div>
            );
          })}
        </div>
        <h4>스킬 빌드</h4>
        <div className="build skills">
          {/* num : Q = 1, W = 2, E = 3, R = 4 */}
          {master.length !== 0 && (
            <div className="skills_m_seq">
              {master.map((skill, index) => (
                <div className="skills" key={index}>
                  <ImageSkill champId={modalData.championId} num={skill.icon} />
                  <span className="texts">{skill.skill}</span>
                </div>
              ))}
            </div>
          )}
          {Object.values(skills).length !== 0 && (
            <div className="skills_seq">
              <div className="qwer">
                <span className="q">Q</span>
                <span className="w">W</span>
                <span className="e">E</span>
                <span className="r">R</span>
                <span className="plus">+</span>
              </div>
              <div className="qwer_per_level">
                <div className="skills q">
                  {skills.skillQ.map((skill, index) =>
                    skill !== 0 ? (
                      <span className="selected" key={index}>
                        {skill}
                      </span>
                    ) : (
                      <span key={index}></span>
                    )
                  )}
                </div>
                <div className="skills w">
                  {skills.skillW.map((skill, index) =>
                    skill !== 0 ? (
                      <span className="selected" key={index}>
                        {skill}
                      </span>
                    ) : (
                      <span key={index}></span>
                    )
                  )}
                </div>
                <div className="skills e">
                  {skills.skillE.map((skill, index) =>
                    skill !== 0 ? (
                      <span className="selected" key={index}>
                        {skill}
                      </span>
                    ) : (
                      <span key={index}></span>
                    )
                  )}
                </div>
                <div className="skills r">
                  {skills.skillR.map((skill, index) =>
                    skill !== 0 ? (
                      <span className="selected" key={index}>
                        {skill}
                      </span>
                    ) : (
                      <span key={index}></span>
                    )
                  )}
                </div>
                <div className="skills plus">
                  {skills.skillPlus.map((skill, index) =>
                    skill !== 0 ? (
                      <span className="selected" key={index}>
                        {skill}
                      </span>
                    ) : (
                      <span key={index}></span>
                    )
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <h4>룬</h4>
        <div className="build runes">
          {Object.values(runes).length !== 0 && (
            <>
              <div className="runes_pri_seq">
                <div className="runes_title">
                  <ImageRune runeId={runes.perkPrimary.id} />
                  <span>{runes.perkPrimary.name}</span>
                </div>
                <div className="runes_row">
                  {runes.perkPrimary.slots.map((slot, index) => (
                    <div className="runes" key={index}>
                      {slot.runes.map((rune) =>
                        modalData.rune_detail.perk0 === rune.id ||
                        modalData.rune_detail.perk1 === rune.id ||
                        modalData.rune_detail.perk2 === rune.id ||
                        modalData.rune_detail.perk3 === rune.id ? (
                          <span className="selected" key={rune.key}>
                            <ImageRune runeId={rune.id} />
                          </span>
                        ) : (
                          <span key={rune.key}>
                            <ImageRune runeId={rune.id} />
                          </span>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="runes_sec_seq">
                <div className="runes_title">
                  <ImageRune runeId={runes.perkSub.id} />
                  <span>{runes.perkSub.name}</span>
                </div>
                <div className="runes_row">
                  {runes.perkSub.slots.map((slot, index) => (
                    <div className="runes" key={index}>
                      {slot.runes.map((rune) =>
                        modalData.rune_detail.perk4 === rune.id ||
                        modalData.rune_detail.perk5 === rune.id ? (
                          <span className="selected" key={rune.key}>
                            <ImageRune runeId={rune.id} />
                          </span>
                        ) : (
                          <span key={rune.key}>
                            <ImageRune runeId={rune.id} />
                          </span>
                        )
                      )}
                    </div>
                  ))}
                </div>
                <div className="runes_row stats">
                  <div className="runes">
                    <span className={`${modalData.rune_detail.statPerk0 === 5008 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                        alt="Adaptive"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk0 === 5005 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAttackSpeedIcon.png"
                        alt="AttackSpeed"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk0 === 5007 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsCDRScalingIcon.png"
                        alt="CDRScaling"
                      />
                    </span>
                  </div>
                  <div className="runes">
                    <span className={`${modalData.rune_detail.statPerk1 === 5008 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsAdaptiveForceIcon.png"
                        alt="Adaptive"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk1 === 5002 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                        alt="Armor"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk1 === 5003 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                        alt="MagicRes"
                      />
                    </span>
                  </div>
                  <div className="runes">
                    <span className={`${modalData.rune_detail.statPerk2 === 5001 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsHealthScalingIcon.png"
                        alt="HealthScaling"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk2 === 5002 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsArmorIcon.png"
                        alt="Armor"
                      />
                    </span>
                    <span className={`${modalData.rune_detail.statPerk2 === 5003 && 'selected'}`}>
                      <img
                        src="//ddragon.leagueoflegends.com/cdn/img/perk-images/StatMods/StatModsMagicResIcon.png"
                        alt="MagicRes"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default React.memo(Modal);
