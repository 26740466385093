import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { range } from 'lodash';

const ChartLp = ({ tierChart }) => {
  const { originalArr, calculatedArr, dateArr } = tierChart;
  // originalArr    : 60개의 원래 티어 정보
  // calculatedArr  : 계산된 티어 포인트
  // dateArr        : 날짜 정보
  // yTickPositions : y축 값 (이제 안씀)
  const minValue = Math.floor(Math.min.apply(null, calculatedArr) / 100) * 100;
  const maxValue = (Math.floor(Math.max.apply(null, calculatedArr) / 100) + 1) * 100;
  const rangeData = range(minValue, maxValue + 100, 100);
  let newRange = rangeData.filter(
    (item, index) =>
      index === 0 || index === rangeData.length - 1 || item % 400 === 0 || item >= 2400
  );
  // console.log(minValue);
  // console.log(calculatedArr, rangeData, newRange);

  const options = {
    // 하이차트 옵션
    chart: {
      type: 'area',
      plotBackgroundColor: 'none',
      backgroundColor: 'none',
      plotBorderColor: 'rgba(0,0,0,0.2)',
      plotBorderWidth: 1,
      width: 300,
      height: 200,
      events: {
        // load: function () {
        //   var point = this.series[0].points;
        //   point.forEach((p) => {
        //     if (Math.max.apply(null, calculatedArr) === p.y) {
        //       p.update({
        //         marker: {
        //           enabled: true,
        //           fillColor: '#46BCED',
        //           radius: 5,
        //           width: 5,
        //           lineColor: '#ffffff',
        //           lineWidth: 2,
        //         },
        //         dataLabels: {
        //           enabled: false,
        //         },
        //       });
        //     }
        //   });
        // },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '11px',
          fontWeight: 400,
          color: '#313131',
        },
        useHTML: true,
        formatter: function () {
          return this.value;
        },
      },
      tickWidth: 1,
      tickColor: '#FFA850',
      tickInterval: 20,
      categories: dateArr,
      lineWidth: 1,
      lineColor: 'rgba(0,0,0,0.5)',
      gridLineWidth: 1,
      gridLineColor: 'rgba(0,0,0,0.2)',
      gridLineDashStyle: 'ShortDash',
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        x: -7,
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '18px',
          fontWeight: 500,
        },
        useHTML: true,
        formatter: function () {
          let thisValue = this.value;
          if (thisValue === 0) return "<span style='color: #5A5959'>I4</span>";
          else if (thisValue === 100) return "<span style='color: #5A5959'>I3</span>";
          else if (thisValue === 200) return "<span style='color: #5A5959'>I2</span>";
          else if (thisValue === 300) return "<span style='color: #5A5959'>I1</span>";
          else if (thisValue === 400) return "<span style='color: #876B26'>B4</span>";
          else if (thisValue === 500) return "<span style='color: #876B26'>B3</span>";
          else if (thisValue === 600) return "<span style='color: #876B26'>B2</span>";
          else if (thisValue === 700) return "<span style='color: #876B26'>B1</span>";
          else if (thisValue === 800) return "<span style='color: #6A79AB'>S4</span>";
          else if (thisValue === 900) return "<span style='color: #6A79AB'>S3</span>";
          else if (thisValue === 1000) return "<span style='color: #6A79AB'>S2</span>";
          else if (thisValue === 1100) return "<span style='color: #6A79AB'>S1</span>";
          else if (thisValue === 1200) return "<span style='color: #BA8D26'>G4</span>";
          else if (thisValue === 1300) return "<span style='color: #BA8D26'>G3</span>";
          else if (thisValue === 1400) return "<span style='color: #BA8D26'>G2</span>";
          else if (thisValue === 1500) return "<span style='color: #BA8D26'>G1</span>";
          else if (thisValue === 1600) return "<span style='color: #1DA794'>P4</span>";
          else if (thisValue === 1700) return "<span style='color: #1DA794'>P3</span>";
          else if (thisValue === 1800) return "<span style='color: #1DA794'>P2</span>";
          else if (thisValue === 1900) return "<span style='color: #1DA794'>P1</span>";
          else if (thisValue === 2000) return "<span style='color: #2898C9'>D4</span>";
          else if (thisValue === 2100) return "<span style='color: #2898C9'>D3</span>";
          else if (thisValue === 2200) return "<span style='color: #2898C9'>D2</span>";
          else if (thisValue === 2300) return "<span style='color: #2898C9'>D1</span>";
          else if (thisValue === 2400) return "<span style='color: #863DA1'>M</span>";
          else if (thisValue === 3400) return "<span style='color: #C23449'>GM</span>";
          else if (thisValue === 4400) return "<span style='color: #EE7A00'>C</span>";
          else return '';
        },
      },
      min: minValue,
      max: maxValue,
      startOnTick: false,
      endOnTick: false,
      tickPositions: newRange,
      tickColor: 'none',
      lineWidth: 1,
      lineColor: 'rgba(0,0,0,0.5)',
      gridLineWidth: 1,
      gridLineColor: 'rgba(0,0,0,0.2)',
      gridLineDashStyle: 'ShortDash',
      plotBands: [
        {
          color: 'rgba(90, 89, 89, 0.08)',
          from: 0,
          to: 400,
        },
        {
          color: 'rgba(135, 107, 38, 0.08)',
          from: 400,
          to: 800,
        },
        {
          color: 'rgba(106, 121, 171, 0.08)',
          from: 800,
          to: 1200,
        },
        {
          color: 'rgba(186, 141, 38, 0.08)',
          from: 1200,
          to: 1600,
        },
        {
          color: 'rgba(29, 167, 148, 0.08)',
          from: 1600,
          to: 2000,
        },
        {
          color: 'rgba(40, 152, 201, 0.08)',
          from: 2000,
          to: 2400,
        },
        {
          color: 'rgba(134, 61, 161, 0.08)',
          from: 2400,
          to: 3400,
        },
        {
          color: 'rgba(194, 52, 73, 0.08)',
          from: 3400,
          to: 4400,
        },
        {
          color: 'rgba(238, 122, 0, 0.08)',
          from: 4400,
          to: 10000,
        },
      ],
    },
    series: [
      {
        type: 'line',
        showInLegend: false,
        name: 'lp graph',
        data: calculatedArr,
        lineWidth: 2,
        lineColor: '#000000',
        marker: {
          enabled: false,
        },
        zones: [
          {
            value: 400,
            color: '#5A5959',
          },
          {
            value: 800,
            color: '#876B26',
          },
          {
            value: 1200,
            color: '#6A79AB',
          },
          {
            value: 1600,
            color: '#BA8D26',
          },
          {
            value: 2000,
            color: '#1DA794',
          },
          {
            value: 2400,
            color: '#2898C9',
          },
          {
            value: 3400,
            color: '#863DA1',
          },
          {
            value: 4400,
            color: '#C23449',
          },
          {
            color: '#EE7A00',
          },
        ],
      },
    ],
    tooltip: {
      backgroundColor: '#ffffff',
      borderColor: '#4C506D',
      borderRadius: 4,
      className: 'tooltip',
      useHTML: true,
      formatter: function () {
        const pointIndex = originalArr[this.point.index];
        return `
          <p><span>${this.x}</span></p>
          <p>랭크 <span class="tier_color ${pointIndex.tier.toLowerCase()}">${pointIndex.tier} ${
          pointIndex.division
        }</span></p>
          <p>LP <span>${pointIndex.lp}</span></p>
        `;
      },
    },
  };

  // xaxis tick 둥글게
  Highcharts.wrap(
    Highcharts.Tick.prototype,
    'getMarkPath',
    function (prev, x, y, tickLength, tickWidth, horiz, renderer) {
      return renderer
        .arc(x, y, Math.round(tickLength / 6), 1, -Math.PI / 2, (3 * Math.PI) / 2)
        .d.split(' ');
    }
  );

  return (
    <>
      {originalArr !== undefined && originalArr.length !== 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <div className="chart_lp">No Data</div>
      )}
    </>
  );
};

export default ChartLp;
