import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 25px;
  background: transparent
    linear-gradient(270deg, rgba(44, 47, 67, 0.9) 0%, rgba(84, 106, 128, 0.9) 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);

  .h__menu {
    display: flex;
    align-items: center;

    h1 {
      margin-top: 5px;

      .beta {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 16px;
        background: transparent linear-gradient(90deg, #2e79bb 0%, #561dab 100%);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        font-size: 11px;
        font-weight: 400;
        color: #fff;
      }
    }

    nav {
      display: flex;
      align-items: center;
      margin-left: 20px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 60px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        &:hover {
          color: #fff;
          background: transparent
            linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(96, 96, 96, 0.3) 100%);
        }
        &.active {
          color: #5df9ff;
        }
      }
    }
  }

  .h__util {
    display: flex;
    align-items: center;

    .lang {
      button {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        color: #dfdfdf;

        &::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          margin-top: 3px;
          margin-right: 9px;
          background: url('/images/ico_lang_w.svg') no-repeat center;
          background-size: cover;
        }
        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #dfdfdf transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s;
          margin-top: 3px;
          margin-left: 9px;
        }
      }
    }
  }
`;

export const FooterContainer = styled.footer`
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: 1130px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .f__copy {
    display: flex;
    align-items: center;

    dl {
      max-width: 620px;
      margin-left: 35px;

      dt {
        font-size: 10px;
        font-weight: 700;
        color: rgba(49, 49, 49, 0.65);
      }
      dd {
        margin-top: 6px;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.18px;
        color: rgba(49, 49, 49, 0.4);
      }
    }
  }

  .f__menu {
    display: flex;
    align-items: center;

    nav {
      display: flex;
      align-items: center;

      a {
        display: inline-block;
        margin: 0 20px;
        padding: 5px;
        font-size: 12px;
      }
    }

    .toggle {
      position: relative;
      width: 127px;
      border-bottom: 1px solid #313131;

      &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 28px;
        padding: 0 8px;
        font-size: 12px;
        font-weight: 400;
        color: #313131;
        cursor: pointer;

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #313131 transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s;
          margin-top: 3px;
          margin-left: 9px;
        }
      }

      &__list {
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-top: 10px;
        padding: 10px 0;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid #313131;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__list-item {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-content: center;
  height: 36px;

  .toggle {
    position: relative;
    width: 72px;
    border-bottom: 1px solid #fff;

    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      font-size: 20px;
      font-weight: 700;
      color: #dfdfdf;
      cursor: pointer;

      &::after {
        content: '';
        overflow: hidden;
        display: inline-block;
        border-color: #dfdfdf transparent transparent transparent;
        border-style: solid;
        border-width: 4px;
        width: 0;
        height: 0;
        transition: all 0.3s;
        margin-top: 3px;
        margin-left: 9px;
      }
    }

    &__list {
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-width: 536px;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid #313131;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__list-item {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 400;
      color: #ababab;
      cursor: pointer;

      &:hover,
      &.selected {
        color: #3362e5;
        background: #dbe8ff;
      }
    }
  }

  .search {
    position: relative;
    width: 406px;
    margin-left: 10px;
    border-bottom: 1px solid #fff;

    &__input {
      width: 100%;
      height: 100%;
      padding: 0 50px 0 10px;
      white-space: normal;
      background: none;
      border: 0;
      font-size: 18px;
      font-weight: 400;
      color: #fff;

      &::placeholder {
        color: #dfdfdf;
        font-weight: 100;
      }
    }

    &__btn {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background: url('/images/ico_search_b.svg') no-repeat center;
      font-size: 0;
      text-indent: -9999px;
    }

    &__autocomplete {
      position: relative;
      z-index: 99;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-width: 536px;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: #fff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid #313131;

      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }

  ${(props) =>
    props.home &&
    css`
      position: relative;
      top: 0;
      left: 0;
      transform: none;

      .toggle {
        border-bottom-color: #313131;
        &__btn {
          color: #313131;

          &::after {
            border-color: #313131 transparent transparent transparent;
            border-style: solid;
            border-width: 4px;
          }
        }
      }
      .search {
        width: 538px;
        border-bottom: 1px solid #313131;
        &__input {
          color: #313131;

          &::placeholder {
            color: rgba(49, 49, 49, 0.5);
          }
        }
        &__btn {
          background: url('/images/ico_search_w.svg') no-repeat center;
        }
      }
    `}
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &.openModal {
    .dimm,
    .modal {
      animation: modal-show 0.5s;
    }
  }

  .dimm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 720px;
    padding: 14px;
    background: #f4f4f5;
    border: 1px solid #5d9ceb;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
    z-index: 20;

    h4 {
      font-size: 16px;
      font-weight: 700;
    }

    .build {
      display: flex;
      margin-top: 10px;
      padding: 9px;
      background-color: #fff;
      border: 1px solid #cad7e2;

      &.items {
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 14px;
        padding: 10px 0;

        .items_per_min {
          flex: 0 1 auto;
          position: relative;
          padding: 10px 15px;

          &::after {
            content: '';
            position: absolute;
            top: 16px;
            left: 0;
            display: block;
            width: 100%;
            height: 16px;
            background: #cad7e2;
          }
          &:last-of-type {
            .items::after {
              display: none;
            }
          }
          .items {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;

            &::after {
              content: '';
              position: absolute;
              top: 11px;
              right: -20px;
              width: 9px;
              height: 8px;
              background: url('/images/icon_arrow_up_w.svg') no-repeat center;
              background-size: cover;
            }

            > span {
              position: relative;
              display: block;
              &.sold {
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.3) url('/images/icon_s_close.svg') no-repeat
                    center;
                }
              }
            }
          }
          .times {
            display: block;
            margin-top: 5px;
            font-size: 10px;
            font-weight: 700;
            text-align: center;
          }
        }
      }
      &.skills {
        align-items: center;
        margin-bottom: 14px;
        padding: 10px 0;

        .skills_m_seq {
          position: relative;
          display: flex;
          width: 180px;
          margin: 0 30px;

          &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            display: block;
            width: 100%;
            height: 16px;
            background: #cad7e2;
          }
          .skills {
            position: relative;
            z-index: 10;

            &::after {
              content: '';
              position: absolute;
              top: 15px;
              right: -24px;
              width: 9px;
              height: 8px;
              background: url('/images/icon_arrow_up_w.svg') no-repeat center;
              background-size: cover;
            }
            &:last-of-type::after {
              display: none;
            }
          }
          .skills + .skills {
            margin-left: 36px;
          }
          .texts {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
          }
        }
        .skills_seq {
          display: flex;

          .qwer {
            display: flex;
            flex-direction: column;
            span {
              background-color: #68749c;
              & + span {
                margin-top: 1px;
              }
              &.plus {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
          .skills {
            display: flex;
            & + .skills {
              margin-top: 1px;
            }
            span {
              margin-left: 1px;
            }
            &.q {
              .selected {
                background: transparent linear-gradient(180deg, #f26884 0%, #ac3e79 100%);
              }
            }
            &.w {
              .selected {
                background: transparent linear-gradient(180deg, #6a70cc 0%, #8630a4 100%);
              }
            }
            &.e {
              .selected {
                background: transparent linear-gradient(180deg, #5eb0db 0%, #415ca4 100%);
              }
            }
            &.r {
              .selected {
                background: transparent linear-gradient(180deg, #f8cb38 0%, #b95c2c 100%);
              }
            }
            &.plus {
              .selected {
                background: transparent linear-gradient(180deg, #56c58e 0%, #376a74 100%);
              }
            }
          }
          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 24px;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.1);
            font-size: 12px;
            color: #fff;
          }
        }
      }
      &.runes {
        justify-content: center;

        > div {
          width: 224px;
          height: 340px;
          margin: 5px 8px;
          padding: 7px;
          background-color: #fff;
          border: 1px solid #cad7e2;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          text-align: center;
        }
        .runes_title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0 24px;
          span {
            display: inline-block;
            margin-left: 15px;
            font-size: 12px;
          }
        }
        .runes {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-bottom: 1px solid #cad7e2;
          &::before {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #cad7e2;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: -4px;
            right: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #cad7e2;
          }
          &:first-of-type {
            &::before,
            &::after {
              display: none;
            }
          }
          &:last-of-type {
            border-bottom: 0;
            &::before,
            &::after {
              display: none;
            }
          }
          span {
            opacity: 0.3;
            mix-blend-mode: luminosity;
            &.selected {
              opacity: 1;
              mix-blend-mode: normal;
            }
          }
        }
        .runes_pri_seq {
          .runes {
            padding: 15px 0;
            &:first-of-type {
              padding: 12px 8px 8px;
              background-color: #dbdbee;
              border-radius: 5px;
              border-bottom: 0;
              .imgRune {
                width: 42px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.9);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
              }
            }
          }
        }
        .runes_sec_seq {
          .runes {
            padding: 9px 0;
            &:first-of-type {
              display: none;
            }
          }
          .stats {
            .runes {
              padding: 8px 0;
              &:first-of-type {
                display: flex;
                &::before,
                &::after {
                  display: inline-block;
                }
              }
              img {
                width: 20px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.9);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
              }
            }
          }
        }
      }
      .imgItem {
        width: 28px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
      }
      .imgSkill {
        width: 36px;
      }
      .imgRune {
        width: 32px;
      }
    }
  }

  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const StorageContainer = styled.div`
  height: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 7px 6px;
    li {
      display: flex;
      align-items: center;
      width: 138px;
      height: 24px;
      margin: 5px 6px;
      background-color: #fff;
      border: 1px solid #7e9ae6;
      border-radius: 3px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      a {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
        font-size: 12px;
        line-height: 1.4;
      }
    }
    &.bookmark_list {
      li a {
        max-width: 100px;
      }
    }
    &.search_list {
      li a {
        max-width: 84px;
      }
    }
  }
  .no_list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 1.4;
    word-break: keep-all;
  }
  .txt {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 0 7px;
  }
  .remove {
    min-width: 22px;
    height: 100%;
    font-size: 0;
    text-indent: -9999px;
    background: url('/images/icon_input_del.svg') no-repeat center;
  }
  .bookmark {
    min-width: 16px;
    height: 16px;
    font-size: 0;
    text-indent: -9999px;
    &.on {
      background: url('/images/ico_star_on.svg') no-repeat center;
    }
    &.off {
      background: url('/images/ico_star_off.svg') no-repeat center;
    }
  }
`;
