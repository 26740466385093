import React from 'react';
import { Link } from 'react-router-dom';

import { FooterContainer } from '../../styled/CommonStyle';

const Footer = () => {
  return (
    <FooterContainer>
      <div className="f__copy">
        <h2>
          <img src="/images/icon_gameeye_logo_w.svg" alt="GAME EYE" />
        </h2>
        <dl>
          <dt>Copyright © GameEye Corp. All rights reserved.</dt>
          <dd>
            deeplol.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of
            Riot Games or anyone officially involved in producing or managing League of Legends.
            <br />
            League of Legends and Riot Games are trademarks or registered trademarks of Riot Games,
            Inc. League of Legends © Riot Games, Inc.
          </dd>
        </dl>
      </div>
      <div className="f__menu">
        <nav>
          <Link to="/">ABOUT</Link>
          <Link to="/">문의/피드백</Link>
        </nav>
        <div className="toggle">
          <span className="toggle__btn">Family Site</span>
          {/* <ul className="toggle__list">
            <li className="toggle__list-item">Family Site</li>
            <li className="toggle__list-item">Family Site</li>
          </ul> */}
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
