// 액션 생성
const MODAL = 'modal/MODAL';

// 액션 함수 내보내기
export const openModal = (open, modalData) => ({ type: MODAL, open, modalData });

// 초기 상태 값
const initialState = {
  open: false,
  modalData: {},
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL:
      return {
        open: action.open,
        modalData: action.modalData,
      };
    default:
      return state;
  }
};
export default reducer;
