import React, { useState } from 'react';
import { useEffect } from 'react';

import { MultiSearchContainer } from '../../styled/MultiStyle';

const MultiSearch = ({ getSummonersName, inpText }) => {
  const [text, setText] = useState('');

  // 찾기버튼 클릭
  const handleSummonerSearch = () => {
    getSummonersName(text);
  };

  // 소환사명 입력
  const handleSummonersName = (e) => {
    const { value } = e.target;

    setText(value);
  };

  useEffect(() => {
    setText(inpText);
    return () => setText();
  }, [inpText]);

  return (
    <MultiSearchContainer>
      <textarea
        onChange={handleSummonersName}
        placeholder="ROX Smeb 님이 로비에 참가하셨습니다.&#10;ROX Peanut 님이 로비에 참가하셨습니다.&#10;ROX Kuro 님이 로비에 참가하셨습니다.&#10;ROX PraY 님이 로비에 참가하셨습니다.&#10;ROX GorillA 님이 로비에 참가하셨습니다."
        spellCheck="false"
        value={text}
      ></textarea>
      <button onClick={handleSummonerSearch}>
        <i>찾기</i>
      </button>
    </MultiSearchContainer>
  );
};

export default MultiSearch;
