import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getSortedObject } from '../../function';

const SplashImage = ({ summonerStat }) => {
  const dataSelector = useSelector((state) => state.imageStore.champions);
  const [imgSrc, setImgSrc] = useState('');
  const [imgName, setImgName] = useState('');

  // 대표 챔피언 이미지
  const getMainChamp = useCallback(
    (champId) => {
      if (dataSelector.length !== 0) {
        setImgName(dataSelector.filter((item) => item.championId === `${champId}`)[0].image_name);
        if (imgName.length !== 0) {
          let result = imgName.split('.');
          setImgSrc(result[0]);
        }
      }
    },
    [dataSelector, imgName]
  );

  useEffect(() => {
    if (Object.keys(summonerStat).length !== 0) {
      Object.keys(summonerStat).map(() => {
        const result = getSortedObject(summonerStat['total'], 'games');
        if (result.length !== 0) {
          return getMainChamp(result[0].cid);
        } else {
          return <span>이미지 없음.</span>;
        }
      });
    } else {
      return <span>이미지 없음.</span>;
    }
  }, [getMainChamp, summonerStat]);

  return (
    <>
      {imgSrc.length !== 0 && (
        <span
          className="img"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_IMG}img/champion/splash/${imgSrc}_0.jpg)`,
          }}
        ></span>
      )}
    </>
  );
};

export default SplashImage;
