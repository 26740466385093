import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// 챔피언 data (현재 안씀)
export const getChampData = async (champId) => {
  const res = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
  const result = res.data.champions.filter((item) => item.championId === `${champId}`);

  return result[0].image_name;
};
// 챔피언 이미지 컴포넌트
export const ImageChamp = ({ champId }) => {
  const version = useSelector((state) => state.gameVersion.version);
  const dataSelector = useSelector((state) => state.imageStore.champions);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (dataSelector.length !== 0) {
      if (dataSelector.filter((item) => item.championId === `${champId}`).length !== 0) {
        setImgName(dataSelector.filter((item) => item.championId === `${champId}`)[0].image_name);
      } else {
        setImgName('');
      }
    }
    return () => {
      setImgName('');
    };
  }, [champId, dataSelector]);

  return (
    <>
      {imgName.length !== 0 ? (
        <img
          src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imgName}`}
          className="imgChamp"
          alt={imgName}
        />
      ) : (
        <img src="/images/icon_non_item.svg" className="imgChamp" alt={imgName} />
      )}
    </>
  );
};

// 룬 이미지 컴포넌트
export const ImageRune = ({ runeId }) => {
  const dataSelector = useSelector((state) => state.imageStore.runes);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (dataSelector.length !== 0) {
      for (const el of dataSelector) {
        if (el.id === runeId) {
          setImgName(el.icon);
        } else {
          const ids = el.slots[0].runes.filter((itm) => itm.id === runeId);
          if (ids.length !== 0) {
            setImgName(ids[0].icon);
          } else {
            el.slots.forEach((slot) => {
              slot.runes.forEach((rune) => {
                if (rune.id === runeId) {
                  setImgName(rune.icon);
                }
              });
            });
          }
        }
      }
    }
    return () => {
      setImgName('');
    };
  }, [runeId, dataSelector]);

  return (
    <>
      {imgName.length !== 0 ? (
        <img src={`${process.env.REACT_APP_IMG}img/${imgName}`} className="imgRune" alt={imgName} />
      ) : (
        <img src="/images/icon_non_item.svg" className="imgRune" alt={imgName} />
      )}
    </>
  );
};

// 스펠 이미지 컴포넌트
export const ImageSpell = ({ spellId, smite }) => {
  const version = useSelector((state) => state.gameVersion.version);
  const dataSelector = useSelector((state) => state.imageStore.spells);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (Object.keys(dataSelector).length !== 0) {
      setImgName(
        Object.keys(dataSelector).filter((item) => dataSelector[item].key === `${spellId}`)
      );
    }
    return () => {
      setImgName('');
    };
  }, [spellId, dataSelector]);

  return (
    <>
      {imgName.length !== 0 ? (
        <>
          {spellId !== 11 ? (
            <img
              src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgName}.png`}
              className="imgSpell"
              alt={imgName}
            />
          ) : (
            <>
              {smite === 'B' ? (
                <img
                  src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_blue.png"
                  className="imgSpell"
                  alt={imgName}
                />
              ) : (
                <img
                  src="https://raw.communitydragon.org/pbe/game/assets/spells/icons2d/smite_red.png"
                  className="imgSpell"
                  alt={imgName}
                />
              )}
            </>
          )}
        </>
      ) : (
        <img src="/images/icon_non_item.svg" className="imgSpell" alt={imgName} />
      )}
    </>
  );
};

// 아이템 이미지 컴포넌트
export const ImageItem = ({ itemId }) => {
  const version = useSelector((state) => state.gameVersion.version);
  const dataSelector = useSelector((state) => state.imageStore.items);
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (Object.keys(dataSelector).length !== 0) {
      let number = Object.keys(dataSelector).filter((item) => item === `${itemId}`);
      setImgName(itemId === 0 ? 0 : dataSelector[number[0]].image.full);
    }
    return () => {
      setImgName('');
    };
  }, [itemId, dataSelector]);

  return (
    <>
      {imgName.length !== 0 && imgName !== 0 ? (
        <img
          src={`${process.env.REACT_APP_IMG}${version}/img/item/${imgName}`}
          className="imgItem"
          alt={imgName}
        />
      ) : (
        <img src="/images/icon_non_item.svg" className="imgItem" alt={imgName} />
      )}
    </>
  );
};

// 스킬 data
export const getSkillImage = async (champName, version, num) => {
  const resSkills = await axios.get(
    `${process.env.REACT_APP_SKILL}?version=${version}&lang=ko_KR&name=${champName}`
  );

  return resSkills.data.data[champName].spells[num - 1].image.full;
};
// 스킬 이미지 컴포넌트
export const ImageSkill = ({ champId, num }) => {
  const version = useSelector((state) => state.gameVersion.version);
  const tmp = version.substr(0, 5);
  const dataSelector = useSelector((state) => state.imageStore.champions);
  const [champName, setChampName] = useState('');
  const [imgName, setImgName] = useState('');

  useEffect(() => {
    if (dataSelector.length !== 0) {
      setChampName(
        dataSelector
          .filter((item) => item.championId === `${champId}`)[0]
          .image_name.replace('.png', '')
      );
    }
    champName.length !== 0 &&
      getSkillImage(champName, tmp, num).then((res) => {
        setImgName(res);
      });
    return () => {
      setChampName('');
    };
  }, [champId, champName, dataSelector, num, tmp]);

  return (
    <>
      {imgName.length !== 0 ? (
        <img
          src={`${process.env.REACT_APP_IMG}${version}/img/spell/${imgName}`}
          className="imgSkill"
          alt={imgName}
        />
      ) : (
        <img src="/images/icon_non_item.svg" className="imgSkill" alt={imgName} />
      )}
    </>
  );
};

// 라인 이미지 컴포넌트
export const ImageLane = ({ lane, color }) => {
  const [imgName, setImgName] = useState(lane);

  useEffect(() => {
    setImgName((prev) => prev.toLowerCase());
    return () => {
      setImgName(lane);
    };
  }, [lane]);

  return (
    <>
      {color === 'white' ? (
        <img src={`/images/icon_s_position_${imgName}_w.svg`} className="imgLane" alt={lane} />
      ) : (
        <img src={`/images/icon_s_position_${imgName}.svg`} className="imgLane" alt={lane} />
      )}
    </>
  );
};

// 오브젝트 이미지 컴포넌트
export const ImageMonster = ({ obj, only, noIcon = false }) => {
  const [imgName, setImgName] = useState(obj);

  useEffect(() => {
    setImgName((prev) => prev.toLowerCase());
    return () => {
      setImgName(obj);
    };
  }, [obj]);

  return (
    <>
      {imgName.indexOf(only) !== -1 ? (
        <img
          src={`/images/icon_objectives_${imgName}.svg`}
          className="imgMonster dragon"
          alt={obj}
        />
      ) : (
        <>
          {noIcon ? (
            <img src={`/images/icon_objectives_${imgName}.png`} className="imgMonster" alt={obj} />
          ) : (
            <img src={`/images/icon_objectives_${imgName}.svg`} className="imgMonster" alt={obj} />
          )}
        </>
      )}
    </>
  );
};
