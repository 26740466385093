import _ from 'lodash';

// 액션 생성
const SEARCHLIST = 'searchList/SEARCHLIST';

// 액션 함수 내보내기
export const searchList = (searchs, flag) => ({ type: SEARCHLIST, searchs, flag });

// 초기 상태 값
const initialState = {
  searchs: [],
  flag: 'add',
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCHLIST:
      const arr = state.searchs.slice(-20);
      return {
        searchs:
          action.flag === 'add'
            ? _.uniqBy([...arr, action.searchs], (e) => e.summoner_id)
            : arr.filter((e) => e.summoner_id !== action.searchs.summoner_id),
      };
    default:
      return state;
  }
};

export default reducer;
