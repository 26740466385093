import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import { Header, Footer } from '../../components';
import Search from '../common/Search';
import BookmarkList from '../common/BookmarkList';
import SearchList from '../common/SearchList';
import { HomeContainer } from '../../styled/HomeStyle';

const Home = () => {
  return (
    <>
      <Header />
      <HomeContainer>
        <div className="home">
          <h2>
            <img src="/images/ico_logo_w.svg" alt="DEEPLOL" />
          </h2>
          <Search home />
          <Tabs>
            <TabList>
              <Tab>즐겨찾기</Tab>
              <Tab>최근검색</Tab>
            </TabList>
            <TabPanel>
              <BookmarkList />
            </TabPanel>
            <TabPanel>
              <SearchList />
            </TabPanel>
          </Tabs>
        </div>
      </HomeContainer>
      <Footer />
    </>
  );
};

export default Home;
