import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import socketio from 'socket.io-client';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import { changeSummoner } from '../../store/modules/summoner';
import { changeVersion } from '../../store/modules/gameVersion';
import { imageStore } from '../../store/modules/imageStore';
import { searchList } from '../../store/modules/searchList';
import { Header, Modal } from '../../components';
import Profile from './Profile';
import Info from './Info';
import MatchList from './MatchList';
import Float from './Float';
import Champ from './Champ';
import { SummonerContainer } from '../../styled/SummonerStyle';

const Summoner = () => {
  const { userName } = useParams();
  const [notFound, setNotFound] = useState(false);
  const [updatePer, setUpdatePer] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [gameLength, setGameLength] = useState(10);
  const [date, setDate] = useState();
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state.modal.open);
  const modalData = useSelector((state) => state.modal.modalData);
  const [moreDisabled, setMoreDisabled] = useState(false);

  let summonerName = userName.replace(/\s/gi, '');
  if (summonerName.length === 2) {
    const result = userName.split('').join('%20');
    summonerName = result;
  }

  // 챔피언, 룬, 스펠, 아이템 스토어 등록 (이미지용)
  const getImageInfo = useCallback(
    async (version) => {
      let _thisGameVersionArr = version.split('.');
      let _thisGameVersion = _thisGameVersionArr[0] + '.' + _thisGameVersionArr[1];

      const resChampions = await axios.get(`${process.env.REACT_APP_CHAMP_INFO}`);
      const resRunes = await axios.get(
        `${process.env.REACT_APP_RUNE}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resSpells = await axios.get(
        `${process.env.REACT_APP_SPELL}?version=${_thisGameVersion}&lang=ko_KR`
      );
      const resItems = await axios.get(
        `${process.env.REACT_APP_ITEM}?version=${_thisGameVersion}&lang=ko_KR`
      );
      dispatch(
        imageStore(
          resChampions.data.champions,
          resRunes.data,
          resSpells.data.data,
          resItems.data.data
        )
      );
    },
    [dispatch]
  );

  // 게임 버전 스토어 등록
  const getGameVersion = useCallback(async () => {
    const res = await axios.get('https://ddragon.leagueoflegends.com/api/versions.json');
    dispatch(changeVersion(res.data[0]));

    // 이미지 정보 함수호출
    getImageInfo(res.data[0]);
  }, [dispatch, getImageInfo]);

  // 정보 업데이트
  const getUpdateTime = useCallback(
    async (info, btn, flag) => {
      setIsLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_API}update_time?summonerId=${info.summoner_id}&region=KR&season=11`
        )
        .then((res) => {
          const data = res.data.result;
          if (data.update === 'Y' || btn !== undefined) {
            // 소켓 도메인/네임스페이스
            const namespace = `${process.env.REACT_APP_API}update_match`;
            // 소켓 연결
            const socket = socketio.connect(
              namespace,
              {
                transports: ['websocket'],
                upgrade: false,
                secure: true,
              },
              { forceNew: true }
            );

            if (!flag) {
              // 업데이트 연결 시 User Connected emit
              socket.on('connect', () => {
                console.log('socket 연결', isUpdated);
                socket.emit('my event', {
                  data: 'User Connected',
                  summoner_id: info.summoner_id,
                  region: 'KR',
                  season: 11,
                  count: 0,
                  offset: 0,
                });
              });
              // 서버에서 데이터 받는 경우
              socket.on('my response', (msg) => {
                if (msg.finish_num !== undefined) {
                  let per = Math.floor((msg.finish_num / msg.match_len) * 100);
                  setUpdatePer(per);
                }
                // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
                if (msg.hasOwnProperty('summoner_id')) {
                  socket.emit('my event', {
                    summoner_id: msg.summoner_id,
                    summoner_key: msg.summoner_key,
                    region: msg.region,
                    match_len: msg.match_len,
                    finish_check: 100,
                    count: msg.count,
                  });
                }
                // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
                if (msg.finish === 100) {
                  socket.disconnect();
                  console.log('socket 연결 종료', isUpdated);
                  setIsUpdated(!isUpdated);
                  setUpdatePer(0);
                }
              });
            } else {
              // 더보기 연결시 User Connected emit
              socket.on('connect', () => {
                console.log('socket 연결', isUpdated);
                socket.emit('my event', {
                  data: 'User Connected',
                  summoner_id: info.summoner_id,
                  region: 'KR',
                  season: 11,
                  count: 0,
                  offset: gameLength,
                });
              });
              // 서버에서 데이터 받는 경우
              socket.on('my response', (msg) => {
                if (msg.finish_num !== undefined) {
                  let per = Math.floor((msg.finish_num / msg.match_len) * 100);
                  setUpdatePer(per);
                }
                // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
                if (msg.hasOwnProperty('summoner_id')) {
                  socket.emit('my event', {
                    summoner_id: msg.summoner_id,
                    summoner_key: msg.summoner_key,
                    region: msg.region,
                    match_len: msg.match_len,
                    finish_check: 100,
                    count: msg.count,
                  });
                }
                // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
                if (msg.finish === 100) {
                  socket.disconnect();
                  console.log('socket 연결 종료', isUpdated);
                  setIsUpdated(!isUpdated);
                  setUpdatePer(0);
                  setGameLength(gameLength + 10);
                  setMoreDisabled(false);
                }
              });
            }
          } else {
            setIsUpdated(true);
            setDate(data);
          }
        });
    },
    [gameLength, isUpdated]
  );

  // 소환사 정보
  const getSummoner = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_API}summoner?summonerName=${encodeURI(summonerName)}&region=KR`)
      .then((res) => {
        const data = res.data.result;
        if (data !== null) {
          // 소환사 있을때
          setNotFound(false);
          dispatch(changeSummoner(data));
          dispatch(searchList(data, 'add'));
          getUpdateTime(data);

          // 게임버전 가져오기
          getGameVersion();
        } else {
          // 소환사 없을때
          setNotFound(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setNotFound(true);
      });
  }, [dispatch, getGameVersion, getUpdateTime, summonerName]);

  // 게임 더 보기 버튼
  const handleMoreGame = (data, btn) => {
    setMoreDisabled(true);
    getUpdateTime(data, btn, true);
  };

  // 업데이트 버튼
  const handleUpdate = (data, btn) => {
    getUpdateTime(data, btn, false);
  };

  useEffect(() => {
    getSummoner();
    if (modalOpen) {
      document.body.style.cssText = `
        position: fixed; 
        top: -${window.scrollY}px;
        overflow-y: scroll;
        width: 100%;
      `;
      return () => {
        const scrollY = document.body.style.top;
        document.body.style.cssText = '';
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      };
    }
  }, [getSummoner, modalOpen]);

  return (
    <>
      <Header />
      <SummonerContainer>
        {notFound ? (
          <div className="not_found">
            <img src="/images/img_not_found.png" alt="?" />
            <h5>
              <span>"{userName}"</span> 는 등록되지 않은 소환사입니다.
            </h5>
            <p>소환사 이름 또는 서버를 올바르게 검색하셨는지 확인해보세요.</p>
          </div>
        ) : isLoading ? (
          <>
            <Profile
              isUpdated={isUpdated}
              updatePer={updatePer}
              handleUpdate={handleUpdate}
              date={date}
            />

            <Tabs className="main_tab">
              <TabList className="main_tab_list">
                <Tab>종합</Tab>
                <Tab disabled={true} onClick={() => alert('준비중입니다.')}>
                  챔피언
                </Tab>
              </TabList>
              <TabPanel>
                {/* 종합 Panel */}
                <Info isUpdated={isUpdated} />
                <MatchList
                  isUpdated={isUpdated}
                  updatePer={updatePer}
                  handleMoreGame={handleMoreGame}
                  moreDisabled={moreDisabled}
                  gameLength={gameLength}
                />
              </TabPanel>
              <TabPanel>
                <Champ />
              </TabPanel>
            </Tabs>

            <Float />
          </>
        ) : (
          <div></div>
        )}
      </SummonerContainer>
      {modalOpen && <Modal modalData={modalData} open={modalOpen} />}
    </>
  );
};

export default React.memo(Summoner);
