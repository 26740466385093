import React, { useState, useEffect, useCallback } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import { ImageChamp, ImageLane, ChartScore, ChartAnalysis } from '../../components';
import { getSortedObject, getSortedArray, scoreColor } from '../../function';

const DetailTab2 = ({ match, myMatch }) => {
  const [originArr, setOriginArr] = useState([]);
  const [ratingAI, setRatingAI] = useState([]);
  const [defaultTabIndex] = useState(myMatch.frameId - 1);

  const initialFunc = useCallback(() => {
    // 원래 match_json
    setOriginArr(getSortedObject(getSortedArray(match.match_json), 'frameId', 'ascending'));

    // AI스코어순 정렬
    setRatingAI(getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'));
  }, [match]);

  useEffect(() => {
    initialFunc();
  }, [initialFunc]);

  return (
    <>
      {ratingAI.length !== 0 ? (
        <Tabs className="analysis_time" defaultIndex={defaultTabIndex}>
          <TabList className="tab">
            <div className="t_head">
              <span>
                <ImageLane lane="Top" />
                <i>Top</i>
              </span>
              <span>
                <ImageLane lane="Jungle" />
                <i>Jug</i>
              </span>
              <span>
                <ImageLane lane="Middle" />
                <i>Mid</i>
              </span>
              <span>
                <ImageLane lane="Bot" />
                <i>Bot</i>
              </span>
              <span>
                <ImageLane lane="Supporter" />
                <i>Sup</i>
              </span>
            </div>
            <div className="t_body">
              <div className="team">
                {originArr.map((item) => (
                  <CustomTab
                    info={item}
                    myMatch={myMatch}
                    className={`${item.win ? 'win' : 'lose'}`}
                    key={item.frameId}
                  >
                    {match}
                  </CustomTab>
                ))}
              </div>
            </div>
          </TabList>
          {originArr.map((item) => (
            <CustomTabPanel info={item} myMatch={myMatch} key={item.frameId} originArr={originArr}>
              {match}
            </CustomTabPanel>
          ))}
        </Tabs>
      ) : (
        <div className="loading_spin">
          <img src="/images/loading.svg" alt="로딩중.." />
        </div>
      )}
    </>
  );
};

// 시간대별 분석 탭 버튼
const CustomTab = ({ children, info, myMatch, ...otherProps }) => (
  <Tab {...otherProps}>
    <div className="rating">
      {info.mvp && <span className="crown mvp"></span>}
      {info.ace && <span className="crown ace"></span>}
      {info.rating}위
    </div>
    <div className="champ">
      <ImageChamp champId={info.championId} />
    </div>
    <div className="score">{scoreColor(info.ai_score)}</div>
    <div className="chart">
      <ChartScore
        data={children.inference_json}
        myMatch={info}
        width={90}
        height={40}
        label={false}
      />
    </div>
  </Tab>
);
CustomTab.tabsRole = 'Tab';

// 시간대별 분석 탭 패널
const CustomTabPanel = ({ children, info, myMatch, originArr, ...otherProps }) => (
  <TabPanel {...otherProps}>
    <ChartAnalysis
      inferenceData={children.inference_json}
      info={info}
      myMatch={myMatch}
      originArr={originArr}
    />
  </TabPanel>
);
CustomTabPanel.tabsRole = 'TabPanel';

export default DetailTab2;
