import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';

import { SearchContainer } from '../../styled/CommonStyle';

const Search = ({ home }) => {
  const inputRef = useRef(null);
  const modalEl = useRef();
  const history = useHistory();
  const [language, setLanguage] = useState('KR');
  const [languageList, setLanguageList] = useState([
    { lang: 'KR', selected: true },
    { lang: 'NA', selected: false },
    { lang: 'BR', selected: false },
    { lang: 'JP', selected: false },
    { lang: 'TW', selected: false },
  ]);
  const [summonerName, setSummonerName] = useState('');
  const [isToggled, setIsToggled] = useState(false);
  const [autocomplete, setAutocomplete] = useState([]);
  const [isOpend, setIsOpend] = useState(false);

  // debounce 된 query로 자동완성
  const sendQuery = async (query) => {
    if (query.length === 0) return;
    try {
      // 추후 수정해야함  ---- parameter에 지역 추가 (지금은 지역상관없이 다나옴)
      // eslint-disable-next-line no-useless-escape
      const regExp = /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi;
      if (!regExp.test(query)) {
        // 특수문자 제거
        const result = query.replace(regExp, '');
        await axios.get(`${process.env.REACT_APP_SEARCH}?term=${result}`).then((res) => {
          const data = res?.data.hits.hit;
          if (data.length !== 0) {
            setAutocomplete(data);
            setIsOpend(true);
          } else {
            setIsOpend(false);
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 자동완성 debounce
  const delayedQueryCall = useRef(_.debounce((q) => sendQuery(q), 500)).current;

  // search input에 값 입력시
  const handleSummonerName = async (e) => {
    const { value } = e.target;
    setSummonerName(value);
    delayedQueryCall(value);

    if (value.length < 1) {
      setAutocomplete([]);
      setIsOpend(false);
    }
  };

  // autocomplete 클릭시
  const handleSummonerSelect = (item) => {
    setSummonerName(item.fields.summonername);
    setIsOpend(false);
    inputRef.current.focus();
  };

  // search button 클릭시
  const handleSummonerSearch = () => {
    history.push({
      pathname: `/summoner/${summonerName}`,
      state: { name: summonerName },
    });
    setIsOpend(false);
  };

  // toggle language 선택시
  const handleLangSelect = (item) => {
    setLanguage(item.lang);
    setIsToggled(false);
    setLanguageList(
      languageList.map((itm) => {
        return item.lang === itm.lang ? { ...itm, selected: true } : { ...itm, selected: false };
      })
    );
  };

  // enter 입력시
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleSummonerSearch();
  };

  // 자동완성 닫기
  const handleClickOutside = useCallback(
    (e) => {
      if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
    },
    [isOpend]
  );

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <SearchContainer home={home}>
      <div className="toggle">
        <span className="toggle__btn" onClick={() => setIsToggled(!isToggled)}>
          {language}
        </span>
        {isToggled && (
          <ul className="toggle__list">
            {languageList.map((item, index) => (
              <li
                key={index}
                className={`toggle__list-item ${item.selected && 'selected'}`}
                onClick={() => handleLangSelect(item)}
              >
                {item.lang}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="search">
        <input
          type="text"
          className="search__input"
          value={summonerName}
          onChange={handleSummonerName}
          onKeyPress={handleKeyPress}
          ref={inputRef}
          placeholder="소환사 명을 입력하세요."
        />
        <button className="search__btn" onClick={handleSummonerSearch}>
          검색
        </button>
        {isOpend && (
          <ul className="search__autocomplete" ref={modalEl}>
            {autocomplete.map((item) => (
              <li
                key={item.id}
                className="search__autocomplete-item"
                onClick={() => handleSummonerSelect(item)}
              >
                {item.fields.summonername}
              </li>
            ))}
          </ul>
        )}
      </div>
    </SearchContainer>
  );
};

export default Search;
