import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { bookmarkList } from '../../store/modules/bookmarkList';
import { getGameTime } from '../../function';
import { ProfileContainer } from '../../styled/SummonerStyle';
import SplashImage from './SplashImage';

const Profile = ({ isUpdated, updatePer, handleUpdate, date }) => {
  const summoner = useSelector((state) => state.summoner.info);
  const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
  const [summonerStat, setSummonerStat] = useState({});
  const [isBookmark, setIsBookmark] = useState(false);
  const dispatch = useDispatch();

  // 즐겨찾기 버튼
  const toggleBookmark = () => {
    if (isBookmark) {
      setIsBookmark(false);
      dispatch(bookmarkList(summoner, 'remove'));
    } else {
      setIsBookmark(true);
      dispatch(bookmarkList(summoner, 'add'));
    }
  };

  useEffect(() => {
    let arr = bookmarks.filter((item) => item.summoner_id === summoner.summoner_id);
    arr.length !== 0 ? setIsBookmark(true) : setIsBookmark(false);
  }, [bookmarks, summoner]);

  // 소환사 데이터 가져오기
  const getData = useCallback(() => {
    // 소환사 stat 정보
    axios
      .get(
        `${process.env.REACT_APP_API}summoner_stat?summonerId=${summoner.summoner_id}&region=KR&season=11`
      )
      .then((res) => {
        const data = res.data.result;
        setSummonerStat(data);
      });
  }, [summoner]);

  useEffect(() => {
    if (isUpdated) {
      getData();
    }
  }, [getData, isUpdated]);

  return (
    <ProfileContainer>
      <div className="p__bg">
        <SplashImage summonerStat={summonerStat} />
      </div>

      <div className="p__info">
        <div className="icon">
          <img
            src={`${process.env.REACT_APP_IMG}${summoner.profile_icon.version}/img/profileicon/${summoner.profile_icon.id}.png`}
            alt={summoner.profile_icon.id}
          />
          <p>{summoner.level}</p>
        </div>
        <div className="text">
          {summoner.pro_info.team !== '-' && (
            <span className="pro">
              {summoner.pro_info.team} {summoner.pro_info.name}
            </span>
          )}
          <div className="name">
            <p>{summoner.summoner_name}</p>
            <div className="favorite">
              <button className={`${isBookmark ? 'on' : 'off'}`} onClick={toggleBookmark}>
                즐겨찾기
              </button>
            </div>
          </div>
          <div className="update">
            {isUpdated ? (
              <button className="complete" onClick={() => handleUpdate(summoner, 'btnClick')}>
                갱신 완료!
              </button>
            ) : (
              <button onClick={() => handleUpdate(summoner, 'btnClick')}>
                <i className="fill" style={{ width: updatePer + '%' }}></i>
                <span>{`${updatePer !== 0 ? updatePer + '%' : '전적갱신'}`}</span>
              </button>
            )}
            <p>
              최근 갱신 : <span>{date ? getGameTime(date.update_time) : '-'}</span>
            </p>
          </div>
        </div>
      </div>
    </ProfileContainer>
  );
};

export default React.memo(Profile);
