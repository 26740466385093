import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import Search from './Search';
import { HeaderContainer } from '../../styled/CommonStyle';

const Header = () => {
  return (
    <HeaderContainer className="h">
      <div className="h__menu">
        <h1>
          <Link to="/">
            <img src="/images/deeplol_logo.svg" alt="DEEPLOL" />
            <span className="beta">BETA</span>
          </Link>
        </h1>
        <nav>
          <NavLink exact to="/">
            홈
          </NavLink>
          <NavLink to="/multi">멀티서치</NavLink>
        </nav>
      </div>
      <Search />
      <div className="h__util">
        <div className="lang">
          <button>KOR</button>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
