import React from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import DetailTab1 from './DetailTab1';
import DetailTab2 from './DetailTab2';
import { MatchDetailContainer } from '../../styled/SummonerStyle';

const MatchDetail = ({ match, myMatch }) => {
  return (
    <MatchDetailContainer>
      <Tabs>
        <TabList>
          <Tab>AI 기본 분석</Tab>
          <Tab>시간대별 분석</Tab>
        </TabList>
        <TabPanel>
          <DetailTab1 match={match} myMatch={myMatch} />
        </TabPanel>
        <TabPanel>
          <DetailTab2 match={match} myMatch={myMatch} />
        </TabPanel>
      </Tabs>
    </MatchDetailContainer>
  );
};

export default React.memo(MatchDetail);
