import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { ChartAi } from '../../components';
import Summary from './Summary';
import { getSortedObject, getSortedArray } from '../../function';
import { OverviewContainer } from '../../styled/SummonerStyle';

const Overview = ({ matchInfo, handleQueueFilter, handleChampFilter, queueNum }) => {
  const summoner = useSelector((state) => state.summoner.info);
  const modalEl = useRef();
  const [data, setData] = useState({});
  const [champName, setChampName] = useState('');
  const [autocomplete, setAutocomplete] = useState([]);
  const [isOpend, setIsOpend] = useState(false);
  const [selectChamp, setSelectChamp] = useState(0);

  // search input에 값 입력시
  const handleChampName = async (e) => {
    const { value } = e.target;
    setChampName(value);

    if (value.length > 0) {
      // 특수문자 제거
      await axios.get(`${process.env.REACT_APP_CHAMP_INFO}?term=${value}`).then((res) => {
        const data = res.data.champions;
        if (data.length !== 0) {
          setAutocomplete(data);
          setIsOpend(true);
        } else {
          setIsOpend(false);
        }
      });
    } else {
      setAutocomplete([]);
      setIsOpend(false);
    }
  };

  // autocomplete 클릭시
  const handleChampSelect = (item) => {
    setChampName(item.champion_name_kr);
    setSelectChamp(item.championId);
    setIsOpend(false);
  };

  // search button 클릭시
  const handleChampSearch = () => {
    setIsOpend(false);
    if (selectChamp === 0) {
      if (autocomplete.length !== 0) {
        handleChampFilter(autocomplete[0].championId);
      } else {
        setChampName('');
        handleChampFilter(0);
      }
    } else {
      handleChampFilter(selectChamp.championId);
    }
  };

  // enter 입력시
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') handleChampSearch();
  };

  // 자동완성 닫기
  const handleClickOutside = useCallback(
    (e) => {
      if (isOpend && (!e.current || !e.current.contains(e.target))) setIsOpend(false);
    },
    [isOpend]
  );

  const getData = useCallback(() => {
    let xAxisArr = [];
    let scoreArr = [];
    let gameArr = [];
    let winloseArr = [];
    let ratingArr = [];

    for (const match of matchInfo) {
      // AI스코어순 rating 추가
      let aiArr = getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'); // AI스코어순 정렬
      let winArr = [];
      let loseArr = [];
      for (let i = 0; i < aiArr.length; i++) {
        aiArr[i].rating = i + 1;
        if (aiArr[i].win) winArr.push(aiArr[i].minmax_z);
        else loseArr.push(aiArr[i].minmax_z);
      }

      // 경기 찾기
      for (const item of match.match_json) {
        for (const key in item) {
          // mvp, ace, ai_score
          if (!match.remake) {
            if (item[key].minmax_z === winArr[0]) item[key].mvp = true;
            if (item[key].minmax_z === loseArr[0]) item[key].ace = true;
            item[key].ai_score = Math.round(item[key].minmax_z * 100);
          }

          // 내 경기
          if (key === summoner.summoner_id) {
            // winloseArr배열 만들기
            winloseArr.push(item[key].win);

            // ratingArr배열 만들기
            ratingArr.push(item[key].rating);

            // scoreArr배열 만들기
            if (item[key].minmax_z === undefined) scoreArr.push(0);
            else scoreArr.push(item[key].minmax_z * 100);

            // gameArr배열 만들기
            if (!match.remake) {
              gameArr.push(item[key]);
            }
          }
        }
      }

      // xAxisArr배열 만들기
      xAxisArr.push(new Date(match.creation_date));
    }
    scoreArr.reverse();
    xAxisArr.reverse();
    winloseArr.reverse();
    ratingArr.reverse();

    return { xAxisArr, scoreArr, gameArr, winloseArr, ratingArr };
  }, [matchInfo, summoner]);

  const getAiChart = useCallback(() => {
    setData(getData());
  }, [getData]);

  useEffect(() => {
    if (matchInfo.length !== 0) {
      getAiChart();
    }
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [getAiChart, handleClickOutside, matchInfo]);

  return (
    <OverviewContainer>
      <div className="o__menu">
        <nav>
          <button
            type="button"
            className={`${queueNum === 0 ? 'selected' : 'btn'}`}
            onClick={() => handleQueueFilter(0)}
          >
            전체
          </button>
          <button
            type="button"
            className={`${queueNum === 420 ? 'selected' : 'btn'}`}
            onClick={() => handleQueueFilter(420)}
          >
            솔로랭크
          </button>
          <button
            type="button"
            className={`${queueNum === 440 ? 'selected' : 'btn'}`}
            onClick={() => handleQueueFilter(440)}
          >
            자유랭크
          </button>
          <button
            type="button"
            className={`${queueNum === 430 ? 'selected' : 'btn'}`}
            onClick={() => handleQueueFilter(430)}
          >
            일반
          </button>
        </nav>
        <div className="champ">
          <div className="champ__search">
            <input
              type="text"
              value={champName}
              onChange={handleChampName}
              onKeyPress={handleKeyPress}
              placeholder="챔피언 검색"
            />
            <button type="button" onClick={handleChampSearch}>
              찾기
            </button>
            {isOpend && (
              <ul className="champ__autocomplete">
                {autocomplete.map((item) => (
                  <li
                    key={item.championId}
                    className="champ__autocomplete-item"
                    onClick={() => handleChampSelect(item)}
                    ref={modalEl}
                  >
                    {item.champion_name_kr}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
      <div className="o__info">
        {matchInfo.length !== 0 ? (
          <>
            <Summary data={data} />
            <ChartAi data={data} />
          </>
        ) : (
          <div className="no_data">정보 없음</div>
        )}
      </div>
    </OverviewContainer>
  );
};

export default Overview;
