import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { searchList } from '../../store/modules/searchList';
import { bookmarkList } from '../../store/modules/bookmarkList';
import { StorageContainer } from '../../styled/CommonStyle';

const SearchList = () => {
  const dispatch = useDispatch();
  const searchs = useSelector((state) => state.searchList.searchs);
  const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);

  // 북마크 추가/제거
  const toggleBookmark = (item) => {
    let arr = bookmarks.filter((itm) => itm.summoner_id === item.summoner_id);
    arr.length !== 0 ? dispatch(bookmarkList(item, 'remove')) : dispatch(bookmarkList(item, 'add'));
  };

  // 최근검색 항목 제거
  const removeSearch = (item) => {
    dispatch(searchList(item, 'remove'));
  };

  return (
    <StorageContainer>
      {searchs.length !== 0 ? (
        <ul className="search_list">
          {searchs.map((item, index) => {
            const bookmarkArr = bookmarks.filter((itm) => item.summoner_name === itm.summoner_name);
            return (
              <li key={index}>
                <p className="txt">
                  <Link to={`/summoner/${item.summoner_name}`}>{item.summoner_name}</Link>
                </p>
                <button
                  className={`bookmark ${bookmarkArr.length !== 0 ? 'on' : 'off'}`}
                  onClick={() => toggleBookmark(item)}
                >
                  즐겨찾기
                </button>
                <button className="remove" onClick={() => removeSearch(item)}>
                  삭제
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="no_list">최근에 본 소환사가 없습니다.</p>
      )}
    </StorageContainer>
  );
};

export default SearchList;
