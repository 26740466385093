import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import socketio from 'socket.io-client';
import { Link } from 'react-router-dom';

import { ImageChamp, ImageLane } from '../../components';
import { getGameTime, scoreColor, kdaColor, tierText, cutLaneName } from '../../function';

const MultiItem = ({ multiItem, setNewItem, duoList, openId, handleLayer }) => {
  const [state, setState] = useState(multiItem);
  const [updated, setUpdated] = useState(100);

  // 소환사 하나씩 데이터 받아오기
  const getSummonerData = useCallback(() => {
    let name = multiItem.summoner_info.summoner_name;

    axios
      .get(
        `${process.env.REACT_APP_API}get_multisearch?summonerName=${encodeURI(
          name
        )}&season=11&region=KR`
      )
      .then((res) => {
        const result = res.data.result.a;

        if (result === undefined) return;
        setState(result);
        setNewItem(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [multiItem.summoner_info.summoner_name, setNewItem]);

  // 소환사 업데이트
  const getSummonerUpdate = useCallback(() => {
    // 소켓 도메인/네임스페이스
    const namespace = `${process.env.REACT_APP_API}update_match_multi`;
    // 소켓 연결
    const socket = socketio.connect(
      namespace,
      {
        transports: ['websocket'],
        upgrade: false,
        secure: true,
      },
      { forceNew: true }
    );
    // 업데이트 연결 시 User Connected emit
    socket.on('connect', () => {
      console.log('multi socket 연결');
      socket.emit('my event multi', {
        data: 'User Connected',
      });
      socket.emit('my event multi', {
        summoner_id: multiItem.summoner_info.summoner_id,
        region: 'KR',
        season: 11,
        count: 0,
      });
    });
    // 서버에서 데이터 받는 경우
    socket.on('my response multi', (msg) => {
      // 카운트
      if (msg.finish_num !== undefined) {
        let per = Math.floor((msg.finish_num / msg.match_len) * 100);
        setUpdated(per);
      }
      // finish가 100이면(서버에서 종료 요청) 소켓 disconnect
      if (msg.finish === 100) {
        console.log('multi socket 연결 종료');
        socket.disconnect();
        getSummonerData();
        setUpdated(100);
      }
      // 받은 데이터에 summoner_id 값이 있으면, 다시 서버에 진행정도 확인 요청
      if (msg.hasOwnProperty('summoner_id')) {
        socket.emit('my event multi', {
          summoner_id: msg.summoner_id,
          summoner_key: msg.summoner_key,
          region: msg.region,
          match_len: msg.match_len,
          finish_check: 100,
          count: msg.count,
        });
      }
    });
  }, [getSummonerData, multiItem.summoner_info.summoner_id]);

  useEffect(() => {
    getSummonerUpdate();
  }, [getSummonerUpdate]);

  return (
    <div className={`row ${updated === 100 ? 'complete' : 'incomplete'}`}>
      <div className="col flex_column1">
        <div className="icons">
          {duoList.map(
            (item, index) =>
              item === state.summoner_info.summoner_name && (
                <span key={index} className="duo">
                  DUO1
                </span>
              )
          )}
          <img
            src={`/images/Emblem_${state.summoner_info.rank_json.RANKED_SOLO_5x5.tier}.png`}
            className="imgEmblem"
            alt={state.summoner_info.rank_json.RANKED_SOLO_5x5.tier}
          />
          <p>
            <ImageLane lane={state.position.reco_lane[0]} />
            <span>{cutLaneName(state.position.reco_lane[0])}</span>
          </p>
        </div>
        <div
          className={`texts ${
            state.continue_winloss.count > 1 ||
            state.tags.carry_tag === 1 ||
            state.tags.check_away_tag === 1 ||
            state.tags.solo_kill_tag === 1 ||
            state.tags.solo_death_tag === 1 ||
            state.tags.roaming_kill_tag === 1 ||
            state.tags.roaming_death_tag === 1 ||
            state.tags.ganking_kill_tag === 1 ||
            state.tags.ganking_death_tag === 1
              ? 'tag_on'
              : 'tag_off'
          }`}
        >
          <div className="tags">
            {state.continue_winloss.win ? (
              <>
                {state.continue_winloss.count > 1 && (
                  <span className="win">{state.continue_winloss.count}연승</span>
                )}
              </>
            ) : (
              <>
                {state.continue_winloss.count > 1 && (
                  <span className="lose">{state.continue_winloss.count}연패</span>
                )}
              </>
            )}
            {state.tags.carry_tag === 1 && <span className="win">캐리</span>}
            {state.tags.check_away_tag === 1 && <span className="lose">탈주</span>}
            {state.tags.solo_kill_tag === 1 && <span className="win">솔킬</span>}
            {state.tags.solo_death_tag === 1 && <span className="lose">솔킬</span>}
            {state.tags.roaming_kill_tag === 1 && <span className="win">로밍</span>}
            {state.tags.roaming_death_tag === 1 && <span className="lose">로밍</span>}
            {state.tags.ganking_kill_tag === 1 && <span className="win">갱킹</span>}
            {state.tags.ganking_death_tag === 1 && <span className="lose">갱킹</span>}
          </div>
          {state.summoner_info.pro_info.team !== '-' && (
            <span>
              {state.summoner_info.pro_info.team} {state.summoner_info.pro_info.name}
            </span>
          )}
          <Link to={`/summoner/${state.summoner_info.summoner_name}`} target="_blank">
            {state.summoner_info.summoner_name}
          </Link>
          <dl className="t1">
            <dt>
              {tierText(
                state.summoner_info.rank_json.RANKED_SOLO_5x5.tier,
                state.summoner_info.rank_json.RANKED_SOLO_5x5.division
              )}
              /
            </dt>
            <dd>{state.summoner_info.rank_json.RANKED_SOLO_5x5.leaguePoints} LP</dd>
          </dl>
          <dl className="t2">
            {Object.values(state.summoner_info.rank_json.RANKED_SOLO_5x5).length !== 0 ? (
              <dt>
                <span>
                  {(
                    (state.summoner_info.rank_json.RANKED_SOLO_5x5.wins /
                      (state.summoner_info.rank_json.RANKED_SOLO_5x5.wins +
                        state.summoner_info.rank_json.RANKED_SOLO_5x5.losses)) *
                    100
                  ).toFixed(1)}
                </span>
                %
              </dt>
            ) : (
              <dt>
                <span>-</span>%
              </dt>
            )}
            /
            {Object.values(state.summoner_info.rank_json.RANKED_SOLO_5x5).length !== 0 ? (
              <dd>
                <span>
                  {state.summoner_info.rank_json.RANKED_SOLO_5x5.wins +
                    state.summoner_info.rank_json.RANKED_SOLO_5x5.losses}
                </span>
                게임
              </dd>
            ) : (
              <dd>
                <span>-</span>
                게임
              </dd>
            )}
          </dl>
          {state.summoner_info.rank_json.RANKED_SOLO_5x5.promos && (
            <div className="promos">
              {state.summoner_info.rank_json.RANKED_SOLO_5x5.promos.progress_list.map(
                (item, index) => (
                  <span key={index} className={item}>
                    {item}
                  </span>
                )
              )}
            </div>
          )}
        </div>
      </div>
      <div className="col flex_column2">
        <div className="aiscore">
          <p>
            {state.ai_score_win_count}승{scoreColor(state.ai_score_win)}
          </p>
          <p>
            {state.ai_score_loss_count}패{scoreColor(state.ai_score_loss)}
          </p>
          <strong>{scoreColor(state.ai_score_total)}</strong>
        </div>
      </div>
      <div className="col flex_column3">
        {state.summoner_match_list.length !== 0 ? (
          <ul className="match_list">
            {state.summoner_match_list.map((item) => (
              <li key={item.timestamp} className="match_item">
                <div className={`box ${item.win ? 'win' : 'lose'}`}>
                  <div className="champ">
                    <ImageChamp champId={item.championId} />
                    <ImageLane lane={item.lane} color="white" />
                  </div>
                  <div className="score">
                    {item.check_away ? scoreColor(0) : scoreColor(item.ai_score.toFixed(0))}
                  </div>
                  <div className="kda">
                    {item.check_away ? (
                      '-'
                    ) : item.death === 0 ? (
                      <span className="kda_color perfect">Perfect</span>
                    ) : (
                      kdaColor(item.kda.toFixed(2))
                    )}
                  </div>
                  <div className="rating">
                    {item.mvp === 1 && <img src="/images/ico_crown_gold_w.svg" alt="mvp" />}
                    {item.ace === 1 && <img src="/images/ico_crown_silver_w.svg" alt="mvp" />}
                    {item.check_away ? (
                      <span className="away">탈주</span>
                    ) : item.ai_rank === 1 ? (
                      item.ai_rank + 'st'
                    ) : item.ai_rank === 2 ? (
                      item.ai_rank + 'nd'
                    ) : item.ai_rank === 3 ? (
                      item.ai_rank + 'rd'
                    ) : (
                      item.ai_rank + 'th'
                    )}
                  </div>
                </div>
                <div className="time">{getGameTime(item.timestamp)}</div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="no_list">최근 게임 기록이 없습니다.</div>
        )}
      </div>
      <div className="col flex_column4">
        {state.summoner_match_list.length !== 0 ? (
          <div className={`float ${openId === state.summoner_info.summoner_id ? 'open' : 'close'}`}>
            <ul className="champ_list">
              {state.champion_stat.map((item, index) => {
                return (
                  <li key={index} className="champ_item">
                    <div className="champ">
                      <ImageChamp champId={Object.keys(item)[0]} />
                    </div>
                    <div className="games">
                      <span>{Object.values(item)[0].games}</span>
                    </div>
                    <div className="winrate">{scoreColor(Object.values(item)[0].winrate)}%</div>
                    <div className="kda">{kdaColor(Object.values(item)[0].kda.toFixed(2))}KDA</div>
                    <div className="score">{scoreColor(Object.values(item)[0].ai_score)}</div>
                  </li>
                );
              })}
            </ul>
            <div className="more">
              <button onClick={() => handleLayer(multiItem.summoner_info.summoner_id)}>
                <img
                  className={openId === state.summoner_info.summoner_id ? 'open' : 'close'}
                  src="/images/icon_arrow_b.svg"
                  alt="열기/닫기"
                />
              </button>
            </div>
          </div>
        ) : (
          <div className="no_list">최근 게임 기록이 없습니다.</div>
        )}
      </div>
    </div>
  );
};

export default MultiItem;
