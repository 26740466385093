import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartAi = ({ data }) => {
  const { scoreArr, xAxisArr, gameArr, winloseArr, ratingArr } = data;
  let last10scoreArr = [];
  let last10xAxisArr = [];
  let last10gameArr = [];
  let last10winloseArr = [];
  let last10ratingArr = [];
  if (gameArr !== undefined) {
    last10scoreArr = scoreArr.length > 10 ? scoreArr.slice(-10) : scoreArr;
    last10xAxisArr = xAxisArr.length > 10 ? xAxisArr.slice(-10) : xAxisArr;
    last10gameArr = gameArr.length > 10 ? gameArr.slice(-10) : gameArr;
    last10winloseArr = winloseArr.length > 10 ? winloseArr.slice(-10) : winloseArr;
    last10ratingArr = ratingArr.length > 10 ? ratingArr.slice(-10) : ratingArr;
  }
  // console.log(last10scoreArr, last10xAxisArr, last10gameArr, last10winloseArr);

  const options = {
    chart: {
      type: 'line',
      plotBackgroundColor: '#EAECEE',
      backgroundColor: 'none',
      width: 530,
      height: 190,
      marginTop: 28,
      marginBottom: 65,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    legend: {},
    xAxis: {
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '11px',
          color: '#313131',
        },
        useHTML: true,
        align: 'center',
        y: 12,
        formatter: function () {
          const winlose = last10winloseArr[this.pos] ? '승' : '패';
          const date = Highcharts.dateFormat('%m.%d', this.value);
          let newResult = date;
          if (date[0] === '0') {
            newResult = date.substr(1);
          }
          return `<div class="chart_ai_label">
                    <span class="${last10winloseArr[this.pos] ? 'win' : 'lose'}">${winlose}</span>
                    <span class="rating">${
                      last10ratingArr[this.pos] === 1
                        ? last10ratingArr[this.pos] + 'st'
                        : last10ratingArr[this.pos] === 2
                        ? last10ratingArr[this.pos] + 'nd'
                        : last10ratingArr[this.pos] === 3
                        ? last10ratingArr[this.pos] + 'rd'
                        : last10ratingArr[this.pos] + 'th'
                    }</span>
                    <span class="date">${newResult}</span>
                  </div>`;
        },
      },
      gridLineWidth: 1,
      gridLineColor: '#fff',
      categories: last10xAxisArr, // 보여지는 x축 값
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '11px',
          color: 'rgba(49, 49, 49, 0.6)',
        },
      },
      min: 0,
      max: 100,
      gridLineWidth: 0,
      lineWidth: 0,
      lineColor: '#f4f4f5',
      plotLines: [
        {
          color: '#006D24',
          width: 1,
          value: 50,
          dashStyle: 'Dash',
        },
      ],
      opposite: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          useHTML: true,
          padding: 0,
          formatter: function () {
            let result = '';
            // 보통 상태
            if (this.y >= 50) {
              result = `<span class="chart_dot good">${this.y.toFixed()}</span>`;
            } else {
              result = `<span class="chart_dot bad">${this.y.toFixed()}</span>`;
            }
            // mvp or ace 상태
            for (let i = 0; i < last10gameArr.length; i++) {
              if (this.y === last10gameArr[i].minmax_z * 100 && last10gameArr[i].mvp) {
                if (this.y >= 50) {
                  result = `<span class="chart_dot good"><i class="crown mvp"></i>${this.y.toFixed()}</span>`;
                } else {
                  result = `<span class="chart_dot bad"><i class="crown mvp"></i>${this.y.toFixed()}</span>`;
                }
              } else if (this.y === last10gameArr[i].minmax_z * 100 && last10gameArr[i].ace) {
                if (this.y >= 50) {
                  result = `<span class="chart_dot good"><i class="crown ace"></i>${this.y.toFixed()}</span>`;
                } else {
                  result = `<span class="chart_dot bad"><i class="crown ace"></i>${this.y.toFixed()}</span>`;
                }
              }
            }
            return result;
          },
        },
        marker: {
          enabled: true,
          lineWidth: 2,
          radius: 6,
          width: 6,
        },
      },
    },
    series: [
      {
        name: 'ai score',
        type: 'line',
        showInLegend: false,
        data: last10scoreArr,
        zones: [
          {
            value: 24,
            color: '#69788d',
          },
          {
            value: 49,
            color: '#de5353',
          },
          {
            value: 75,
            color: '#259a7e',
          },
          {
            color: '#f19000',
          },
        ],
        lineWidth: 2,
      },
    ],
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className="chart_box">
      <h5>AI-Score Graph</h5>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartAi;
