import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // 로컬스토리지를 불러옵니다

import theme from './modules/theme';
import summoner from './modules/summoner';
import gameVersion from './modules/gameVersion';
import imageStore from './modules/imageStore';
import modal from './modules/modal';
import searchList from './modules/searchList';
import bookmarkList from './modules/bookmarkList';

const persistConfig = {
  key: 'persistStorage',
  storage, // localStorage에 저장합니다.
  whitelist: ['searchList', 'bookmarkList'], // 여러개의 reducer 중에 searchList만 localstorage에 저장합니다.
  // blacklist -> 그것만 제외합니다
};

const rootReducer = combineReducers({
  theme,
  summoner,
  gameVersion,
  imageStore,
  modal,
  searchList,
  bookmarkList,
});

export default persistReducer(persistConfig, rootReducer);
