import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { bookmarkList } from '../../store/modules/bookmarkList';
import { StorageContainer } from '../../styled/CommonStyle';

const BookmarkList = () => {
  const dispatch = useDispatch();
  const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);

  // 즐겨찾기 항목 제거
  const removeBookmark = (item) => {
    dispatch(bookmarkList(item, 'remove'));
  };

  return (
    <StorageContainer>
      {bookmarks.length !== 0 ? (
        <ul className="bookmark_list">
          {bookmarks.map((item, index) => (
            <li key={index}>
              <p className="txt">
                <Link to={`/summoner/${item.summoner_name}`}>{item.summoner_name}</Link>
              </p>
              <button className="remove" onClick={() => removeBookmark(item)}>
                삭제
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no_list">관심있는 소환사에 즐겨찾기를 하여 편리하게 정보를 받아보세요.</p>
      )}
    </StorageContainer>
  );
};

export default BookmarkList;
