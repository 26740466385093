import React, { useState, useEffect, useCallback } from 'react';

import MultiItem from './MultiItem';
import { MultiListContainer } from '../../styled/MultiStyle';

const MultiList = ({ multiList, getAvg }) => {
  const [newItem, setNewItem] = useState({});
  const [list, setList] = useState([]);
  const [duoList, setDuoList] = useState([]);
  const [openId, setOpenId] = useState(0);

  const handleLayer = (id) => {
    const currentId = openId;
    if (currentId === id) {
      setOpenId(0);
    } else {
      setOpenId(id);
    }
  };

  const getProcessData = useCallback(
    (arr) => {
      let newArr = [];
      arr.forEach((item, index) => {
        if (index !== 0) {
          newArr.push(item);
        }
      });

      let total = 0;
      let tmp = [];
      newArr.forEach((item, index) => {
        total += item.ai_score_total;
        let aa = 0;
        let bb = '';
        item.summoner_match_list.forEach((itm, idx) => {
          aa = itm.matchId;
          bb = itm.side;
          newArr.forEach((ii, xx) => {
            if (index !== xx) {
              ii.summoner_match_list.forEach((i, x) => {
                if (aa === i.matchId && bb === i.side) {
                  tmp.push(ii.summoner_info.summoner_name);
                }
              });
            }
          });
        });
      });

      let set = new Set(tmp);
      setDuoList([...set]);

      getAvg((total / newArr.length).toFixed(1));
    },
    [getAvg]
  );

  useEffect(() => {
    setList((list) => {
      return [...list, newItem];
    });
  }, [newItem]);

  useEffect(() => {
    getProcessData(list);
  }, [getProcessData, list]);

  return (
    <MultiListContainer>
      <div className="t_head">
        <div className="row">
          <div className="col flex_column1">소환사 정보</div>
          <div className="col flex_column2">AI-Score 평균</div>
          <div className="col flex_column3">
            최근 게임 기록<span>(AI-Score, KDA, 순위)</span>
          </div>
          <div className="col flex_column4">최근 선호 챔피언</div>
        </div>
      </div>
      <div className="t_body">
        {multiList.map((multiItem, index) => (
          <MultiItem
            key={index}
            multiItem={multiItem}
            setNewItem={setNewItem}
            duoList={duoList}
            openId={openId}
            handleLayer={handleLayer}
          />
        ))}
      </div>
    </MultiListContainer>
  );
};

export default MultiList;
