// 액션 생성
const IMAGE_STORE = 'imageStore/IMAGE_STORE';

// 액션 함수 내보내기
export const imageStore = (champions, runes, spells, items, skills) => ({
  type: IMAGE_STORE,
  champions,
  runes,
  spells,
  items,
  skills,
});

// 초기 상태 값
const initialState = {
  champions: [],
  spells: {},
  runes: [],
  items: {},
  skills: {},
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_STORE:
      return {
        champions: action.champions,
        spells: action.spells,
        runes: action.runes,
        items: action.items,
        skills: action.skills,
      };
    default:
      return state;
  }
};
export default reducer;
