import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const ChartScore = ({ data, myMatch, width, height, label }) => {
  // 포지션 찾기
  let side = myMatch.side === 'blue' ? 'B_' : 'R_';
  let lane = '';
  switch (myMatch.lane) {
    case 'Top':
      lane = 'Top';
      break;
    case 'Jungle':
      lane = 'Jun';
      break;
    case 'Middle':
      lane = 'Mid';
      break;
    case 'Bot':
      lane = 'Bot';
      break;
    case 'Supporter':
      lane = 'Sup';
      break;
    default:
      break;
  }
  const position = side + lane;
  const xAxisArr = data !== 'remake match' && data.team_lane_time_dict[position];
  const scoreArr = data !== 'remake match' && data.team_lane_score_dict[position];

  const options = {
    chart: {
      type: 'line',
      plotBackgroundColor: 'rgba(255,255,255,0.3)',
      backgroundColor: 'none',
      width: width,
      height: height,
      spacing: [5, 2, 5, 2],
      events: {
        load: function () {
          let point = this.series[0].points;
          point.forEach((p, i) => {
            if (Math.max.apply(null, scoreArr) === p.y) {
              p.update({
                marker: {
                  enabled: true,
                  fillColor: '#36AE8D',
                  radius: 4,
                  width: 4,
                  lineColor: '#ffffff',
                  lineWidth: 2,
                },
              });
            }
          });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    subtitle: {
      text: '',
    },
    legend: {},
    xAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
      tickColor: 'transparent',
      lineWidth: 1,
      lineColor: 'rgba(0,0,0,0.5)',
      gridLineWidth: 1,
      gridLineColor: '#C5C5C5',
      categories: xAxisArr, // 보여지는 x축 값
    },
    yAxis: {
      title: {
        enabled: false,
      },
      labels: {
        enabled: label,
        style: {
          fontFamily: 'Noto Sans KR',
          fontSize: '10px',
          color: '#313131',
        },
        x: 5,
      },
      min: 0,
      max: 100,
      tickInterval: 50,
      opposite: true,
      lineWidth: 1,
      lineColor: 'rgba(0,0,0,0.5)',
      gridLineWidth: 0,
      plotLines: [
        {
          color: 'rgba(0, 0, 0, 0.4)',
          width: 1,
          value: 50,
          dashStyle: 'dash',
        },
      ],
      minorGridLineWidth: 1,
      minorGridLineColor: '#C5C5C5',
      minorTickInterval: 25,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series: [
      {
        name: 'my score',
        type: 'line',
        showInLegend: false,
        data: scoreArr,
        zones: [
          {
            value: 24,
            color: '#69788d',
          },
          {
            value: 49,
            color: '#de5353',
          },
          {
            value: 75,
            color: '#259a7e',
          },
          {
            color: '#f19000',
          },
        ],
        lineWidth: 2,
      },
    ],
    tooltip: {
      enabled: false,
    },
  };

  return (
    <>
      {scoreArr !== undefined && scoreArr.length !== 0 ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <div>No Data</div>
      )}
    </>
  );
};

export default ChartScore;
