import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { openModal } from '../../store/modules/modal';
import MatchDetail from './MatchDetail';
import { ImageChamp, ImageRune, ImageSpell, ImageLane, ImageItem } from '../../components';
import {
  getGameTime,
  getTimeHMS,
  getKda,
  csPerMinute,
  scoreColor,
  kdaColor,
  tierAvgText,
  cutLaneName,
} from '../../function';
import { ChartScore } from '../../components';
import { MatchItemContainer } from '../../styled/SummonerStyle';

const MatchItem = ({ match, openId, handleMoreInfo }) => {
  const summoner = useSelector((state) => state.summoner.info);
  const [myMatch, setMyMatch] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    // 내 경기 정보
    match.match_json.map(
      (item) => Object.keys(item)[0] === summoner.summoner_id && setMyMatch(Object.values(item)[0])
    );
  }, [match, summoner]);

  return (
    <MatchItemContainer>
      {Object.keys(myMatch).length !== 0 && (
        <div
          className={`rowContainer ${match.remake ? 'remake' : myMatch.win ? 'win' : 'lose'} ${
            openId === match.gameId ? 'open' : 'close'
          }`}
        >
          <div className="row">
            <div className="col flex_column0"></div>
            <div className="col flex_column1">
              <h5>
                {match.queue === 'RANKED_SOLO_5x5' && '솔로랭크'}
                {match.queue === 'RANKED_FLEX_SR' && '자유랭크'}
                {match.queue === 'NORMAL_5V5_BLIND_PICK' && '일반게임'}
              </h5>
              <p>{getGameTime(match.date_timestamp)}</p>
              <dl>
                <dt>
                  <span>{match.remake ? '다시하기' : myMatch.win ? '승리' : '패배'}</span>
                  {getTimeHMS(match.duration)}
                </dt>
                <dd>
                  평균 <div>{tierAvgText(match.tier_avg)}</div>
                </dd>
              </dl>
            </div>
            <div className="col flex_column2">
              <dl>
                <dt className={`${myMatch.check_away ? 'away' : 'home'}`}>
                  <ImageChamp champId={myMatch.championId} />
                  <p>
                    <ImageLane lane={myMatch.lane} />
                    <span>{cutLaneName(myMatch.lane)}</span>
                  </p>
                </dt>
                <dd>
                  <ImageSpell spellId={myMatch.summonerSpellDId} smite={myMatch.smite} />
                  <ImageSpell spellId={myMatch.summonerSpellFId} smite={myMatch.smite} />
                </dd>
                <dd>
                  <ImageRune runeId={myMatch.rune_detail.perk0} />
                  <ImageRune runeId={myMatch.rune_detail.perkSubStyle} />
                </dd>
              </dl>
            </div>
            <div className="col flex_column3">
              <div className="cbox">
                <h5>AI-Score</h5>
                <h4>{scoreColor(myMatch.ai_score)}</h4>
                <p>
                  {myMatch.mvp && <span className="crown mvp"></span>}
                  {myMatch.ace && <span className="crown ace"></span>}
                  {match.remake ? (
                    <>
                      <i>-</i>
                    </>
                  ) : (
                    <>
                      {myMatch.rating === 1 ? (
                        <>
                          <i>1</i>st
                        </>
                      ) : myMatch.rating === 2 ? (
                        <>
                          <i>2</i>nd
                        </>
                      ) : (
                        <>
                          <i>{myMatch.rating}</i>th
                        </>
                      )}
                    </>
                  )}
                </p>
              </div>
              <ChartScore
                data={match.inference_json}
                myMatch={myMatch}
                width={175}
                height={90}
                label={true}
              />
            </div>
            <div className="col flex_column4">
              <p className="type1">
                Level<span>{myMatch.champLevel}</span>
              </p>
              <p className="type2">
                <span>{myMatch.kills}</span>/<span className="death">{myMatch.deaths}</span>/
                <span>{myMatch.assists}</span>
              </p>
              <p className="type3">
                {myMatch.deaths === 0 ? (
                  <span className="kda_color perfect">Perfect</span>
                ) : (
                  <>
                    {kdaColor(getKda(myMatch.kills, myMatch.deaths, myMatch.assists).toFixed(2))}
                    <span>KDA</span>
                  </>
                )}
              </p>
              <p className="type4">
                <span>{myMatch.cs}</span>CS (<i>{csPerMinute(myMatch.cs, match.duration)}</i>/분)
              </p>
              <p className="type5">
                <span>{myMatch.kill_point.toFixed(1)}</span>% 킬관여
              </p>
            </div>
            <div className="col flex_column5">
              <div className="cbox">
                <ImageItem itemId={myMatch.item_detail.item0} />
                <ImageItem itemId={myMatch.item_detail.item1} />
                <ImageItem itemId={myMatch.item_detail.item2} />
                <ImageItem itemId={myMatch.item_detail.item6} />
                <ImageItem itemId={myMatch.item_detail.item3} />
                <ImageItem itemId={myMatch.item_detail.item4} />
                <ImageItem itemId={myMatch.item_detail.item5} />
                <img
                  src="/images/btn_item_build_search_w.svg"
                  className="build_detail"
                  onClick={() => dispatch(openModal(true, myMatch))}
                  alt="자세히"
                />
              </div>
              <div className="vision">
                <span>
                  <img src="/images/ico_ward01.svg" alt="구매한 와드 수" />
                  {myMatch.visionWardsBoughtInGame}
                </span>
                <span>
                  <img src="/images/ico_ward02.svg" alt="설치한 와드 수" />
                  {myMatch.wardsPlaced}
                </span>
                <span>
                  <img src="/images/ico_ward03.svg" alt="제거한 와드 수" />
                  {myMatch.wardsKilled}
                </span>
              </div>
            </div>
            <div className="col flex_column6">
              <div className="team">
                {match.match_json.map((item, index) => {
                  const player = Object.values(item);
                  return (
                    index < 5 && (
                      <div
                        key={player[0].frameId}
                        className={`${
                          player[0].summonerName === myMatch.summonerName ? 'my' : 'player'
                        }`}
                      >
                        <ImageChamp champId={player[0].championId} />
                        <p>
                          <Link to={`/summoner/${player[0].summonerName}`} target="_blank">
                            {player[0].summonerName}
                          </Link>
                        </p>
                      </div>
                    )
                  );
                })}
              </div>
              <div className="team">
                {match.match_json.map((item, index) => {
                  const player = Object.values(item);
                  return (
                    index >= 5 && (
                      <div
                        key={player[0].frameId}
                        className={`${
                          player[0].summonerName === myMatch.summonerName ? 'my' : 'player'
                        }`}
                      >
                        <ImageChamp champId={player[0].championId} />
                        <p>
                          <Link to={`/summoner/${player[0].summonerName}`} target="_blank">
                            {player[0].summonerName}
                          </Link>
                        </p>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
            <div className="col flex_column7">
              <button onClick={() => handleMoreInfo(match.gameId)}>
                <img src="/images/icon_arrow_b.svg" alt="더 보기" />
              </button>
            </div>
          </div>
          {openId === match.gameId && <MatchDetail match={match} myMatch={myMatch} />}
        </div>
      )}
    </MatchItemContainer>
  );
};

export default React.memo(MatchItem);
